import React, { useEffect, useState } from "react";
import Head from "../NavBar/Head";
import { Row, Col, Card, Modal, Form, Spinner, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { SET_DEFAULT_VOUCHER_TEMPLATE } from "../../store/Theme/ThemeActionTypes";
import { ToastContainer, toast } from "react-toastify";
const templates = [
  {
    id: 1,
    title: "Template 1",
    isSelected: false,
    image: "/images/voucherTemp1.png",
    showImage: "/images/voucherTemp1.png",
  },
  {
    id: 3,
    title: "Template 2",
    isSelected: false,
    image: "/images/voucherTemp2.png",
    showImage: "/images/voucherTemp2.png",
  },

  {
    id: 5,
    title: "Template 6",
    isSelected: false,
    image: "/images/voucherTemp3.png",
    showImage: "/images/voucherTemp3.png",
  },
];

export default function VoucherTemplates(props) {
  const { setIsShow = false, isShow = false } = props;
  const { state } = useLocation();
  const navigate = useNavigate();
  const { VoucherTemplate } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state.userInfo);
  const [showTemplate, setShowTemplate] = useState({
    isOpen: false,
    templateId: 1,
    templateTitle: "",
  });
  const [showSpinner, setShowSpinner] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [showTemplate?.isOpen]);

  const dispatch = useDispatch();

  const handleCloseTemplate = () => {
    setIsChecked(false);
    setShowSpinner(true);
    setShowTemplate({
      ...showTemplate,
      isOpen: false,
      templateId: 1,
      templateTitle: "",
    });
  };
  const getDefaultTemplates = async (id) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}templates/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
            Accept: "application/json",
          },
        }
      );

      if (result?.data?.template) {
        dispatch({
          type: SET_DEFAULT_VOUCHER_TEMPLATE,
          data: parseInt(result.data?.templateId?.id, 10) || VoucherTemplate,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_DEFAULT_VOUCHER_TEMPLATE,
        data:
          userInfo.user_data.default_invoice_template === "7"
            ? 5
            : parseInt(userInfo.user_data.default_invoice_template, 10) ||
              VoucherTemplate,
      });
    }
  };
  useEffect(() => {
    getDefaultTemplates(userInfo?.user_data?.id);
    // dispatch({
    //   type: SET_DEFAULT_VOUCHER_TEMPLATE,
    //   data:
    //     parseInt(userInfo.user_data.default_invoice_template, 10) ||
    //     VoucherTemplate,
    // });
  }, []);

  const handleUpdateTemplates = (title, id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}create-or-update-template/${id}`,
        {
          temp_invoice_id: id?.toString(),
          temp_vouch_id: userInfo?.temp_invoice_id || "0",
          // template_id: id?.toString(),
          user_id: userInfo?.user_data?.id?.toString(),
          template_type: "invoice",
          name: title,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          dispatch({
            type: SET_DEFAULT_VOUCHER_TEMPLATE,
            data: parseInt(res?.data?.templateId?.id, 10),
          });
          toast.success("Default template set for voucher ", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  return (
    <div className={showTemplate.isOpen ? "blur-background" : ""}>
      {!isShow && <Head title="Voucher templates" />}
      <Row className="g-3">
        {templates
          ?.sort((a, b) => a.id - b.id)
          .map((item) => (
            <Col md={4}>
              <Card
                className="border-0 shadow-sm"
                onClick={() => {
                  setShowTemplate({
                    ...showTemplate,
                    isOpen: true,
                    templateId: item.id,
                    templateTitle: item.title,
                  });
                }}
              >
                <Card.Body className="p-1">
                  <div
                    className="p-2"
                    style={{
                      background: `url(${item.image})`,
                      height: 220,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  {VoucherTemplate === item.id && (
                    <div className="p-2 d-flex align-items-center position-absolute bottom-0 bg-light end-0 start-0 rounded justify-content-between">
                      <p className="fs-6 m-0">Default Template</p>
                      <img
                        src="/images/check-mark.png"
                        style={{
                          width: "30px",
                        }}
                        alt="check-icon"
                      />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
      <Modal
        className={!isChecked ? "d-block" : "d-none"}
        size="lg"
        center
        show={showTemplate?.isOpen}
        onHide={handleCloseTemplate}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            {!showSpinner ? (
              <>
                <Form.Group md="auto">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Check to select the template as default
                      </Tooltip>
                    }
                  >
                    <Form.Check
                      checked={
                        VoucherTemplate === showTemplate?.templateId &&
                        !(
                          state?.requestType === "edit" ||
                          isShow?.requestType === "edit"
                        )
                          ? true
                          : isChecked
                      }
                      // onChange={() => setIsChecked(!isChecked)}
                      onChange={() => {
                        if (
                          VoucherTemplate === showTemplate?.templateId &&
                          !(
                            state?.requestType === "edit" ||
                            isShow?.requestType === "edit"
                          )
                        ) {
                          // toast.warn("This template is already selected as default.");
                          alert(
                            "This template is already selected as default."
                          );
                        } else {
                          setIsChecked(!isChecked);
                        }
                      }}
                      type="checkbox"
                      label={
                        state?.requestType !== undefined
                          ? "Select Your Template"
                          : "Select Your Default Template"
                      }
                    />
                  </OverlayTrigger>
                </Form.Group>
              </>
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "100%" }}>
            {showSpinner ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "90vh" }}
              >
                <Spinner animation="border" role="status" />
              </div>
            ) : (
              <img
                src={
                  templates.filter(
                    (row) => row.id === parseInt(showTemplate?.templateId, 10)
                  )[0]?.showImage
                }
                alt="img"
                width="100%"
                height="100%"
              ></img>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={
          VoucherTemplate === showTemplate?.templateId &&
          !(state?.requestType === "edit" || isShow?.requestType === "edit")
            ? false
            : isChecked
        }
        onHide={() => setIsChecked(false)}
        centered
      >
        {" "}
        <Modal.Header className="d-flex justify-content-center border-0">
          <Modal.Title style={{ color: "black" }}>Confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {state?.requestType !== undefined
            ? "Are you sure want to apply this template?"
            : "Are you sure want to apply this template as default?"}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            className="closeButton"
            onClick={() => setIsChecked(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (state?.values && state?.requestType === "edit") {
                navigate(`/vouchers/edit/${state?.values?.id}`, {
                  state: {
                    values: {
                      ...state.values,
                      template_id: parseInt(showTemplate?.templateId, 10),
                    },
                    isTemplateChange: true,
                  },
                });
              } else if (
                isShow?.requestType === "edit" ||
                isShow?.requestType === "view"
              ) {
                setIsShow({
                  ...isShow,
                  needTemplateChange: false,
                  values: {
                    ...isShow?.values,
                    template_id: parseInt(showTemplate?.templateId, 10),
                  },
                });
              } else if (state?.values) {
                navigate("/create-new-voucher", {
                  state: {
                    values: {
                      ...state.values,
                      template_id: parseInt(showTemplate?.templateId, 10),
                    },
                    isTemplateChange: true,
                  },
                });
              } else {
                if (isChecked) {
                  handleUpdateTemplates(
                    showTemplate?.templateTitle,
                    showTemplate?.templateId
                  );
                }
              }
              setIsChecked(false);
              setShowTemplate({
                ...showTemplate,
                isOpen: false,
                templateId: 1,
                templateTitle: "",
              });
            }}
          >
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer style={{ top: "60px" }} />{" "}
    </div>
  );
}
