import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import DataTables from "../components/DataTables";
import { BsEye, BsPen, BsTrash3 } from "react-icons/bs";
import ReactDOMServer from "react-dom/server";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Modals from "../components/Modal";
import Head from "../components/NavBar/Head";
import { FETCH_ROLE_SUCCESS } from "../store/Role/RoleActionTypes";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import moment from "moment";
import { Tooltip as ReactTooltip } from "react-tooltip";

const permissibleKeys = [
  "template",
  "users",
  "roles",
  "invoice",
  "vouchers",
  "khata_book",
  "cash_book",
];
const crudPermissionType = ["Create", "Edit", "View", "Delete"];

const Roles = () => {
  const [modalActions, setModalActions] = useState({
    showModal: false,
    modalTitle: "Create New Role",
    submitButtonTitle: "",
  });
  const [deleteRoleAction, setDeleteRoleAction] = useState({
    showConfirmationModal: false,
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const { roles } = useSelector((state) => state?.roles);
  const { userInfo } = useSelector((state) => state?.userInfo);
  const checkRole = userInfo?.user_data?.role;
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const formatDate = userInfo?.account_preferences?.date_formate;

  const checkAll = document.querySelector(".checkAll");
  const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");

  const [isDeleting, setIsDeleting] = useState(false);

  const columns = [
    {
      data: "id",
      class: "d-none",
    },
    {
      data: null,
      title:
        '<input class="form-check-input checkAll" type="checkbox" data-action="checkAll">',
      width: "20px",
      class: "text-center align-middle",
      orderable: false,
      visible:
        checkRole?.roles?.includes("delete") ||
        checkRole?.roles?.includes("Delete"),
      render: function (data, type, row) {
        return row["role"] === "admin"
          ? ""
          : `<input type="checkbox" class="form-check-input checkBox" name="posts[]" value="` +
              row["id"] +
              `" data-action="checkBox">`;
      },
    },
    {
      data: "role",
      class: "text-left align-middle",
      title: "Title",
    },
    {
      data: "author",
      class: "text-left align-middle",
      title: "Created By",
      render: function (data, type, row) {
        if (row["author"] === undefined || row["author"] === null) return "-";
        return `${row["author"]["firstname"]} ${row["author"]["lastname"]}`;
      },
    },
    {
      data: "created_at",
      class: "text-left align-middle",
      title: "Created On",
      render: function (data, type, row) {
        if (row["created_at"] === null) return "-";
        return `${moment(row["created_at"] || row["updated_at"]).format(
          `${
            formatDate === null || formatDate === undefined
              ? "DD MMM, YYYY"
              : formatDate
          }`
        )}`;
      },
    },

    {
      data: null,
      class: "text-left align-middle  ",
      title: "Actions",
      width: "150px",
      orderable: false,
      render: function (data, type, row) {
        return ReactDOMServer.renderToString(
          <div className="text-right">
            {(checkRole?.roles?.includes("view") ||
              checkRole?.roles?.includes("View")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="View"
                variant="light"
                size="sm"
                className="btn-icon mx-1"
                data-id={JSON.stringify(row)}
                data-action="viewRoleInfo"
              >
                <BsEye size={17} />
              </Button>
            )}
            {row["role"] === "admin" ? null : (
              <>
                {(checkRole?.roles?.includes("edit") ||
                  checkRole?.roles?.includes("Edit")) && (
                  <Button
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Edit"
                    variant="light"
                    size="sm"
                    className=" btn-icon mx-1"
                    data-id={JSON.stringify(row)}
                    data-action="updateRoleInfo"
                  >
                    <BsPen size={17} />
                  </Button>
                )}
                {(checkRole?.roles?.includes("delete") ||
                  checkRole?.roles?.includes("Delete")) && (
                  <Button
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Delete"
                    variant="light"
                    size="sm"
                    className="btn-icon text-danger mx-1"
                    data-id={row.id}
                    data-action="confirmBeforeDeletion"
                  >
                    <BsTrash3 size={17} />
                  </Button>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const rolesValidationSchema = Yup.object().shape({
    role: Yup.string()
      .required()
      .label("Role Title")
      .test("uniqueRoleName", "Role already exist", async (value) => {
        const existingRoles = roles
          ?.filter((key) => key?.id !== watch("id"))
          ?.map((row) => row.role?.toLowerCase());
        return !existingRoles.includes(value?.toLowerCase());
      }),
    roles: Yup.array().of(Yup.string()),
    invoice: Yup.array().of(Yup.string()),
    template: Yup.array().of(Yup.string()),
    users: Yup.array().of(Yup.string()),
  });
  const {
    register,
    reset,
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rolesValidationSchema),
  });
  const {
    fields: invoicesField = [],
    append: appendInvoices,
    remove: removeInvoices,
  } = useFieldArray({
    control,
    name: "invoice",
  });
  const {
    fields: vouchersField = [],
    append: appendVouchers,
    remove: removeVouchers,
  } = useFieldArray({
    control,
    name: "vouchers",
  });

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };

  const {
    fields: roleField,
    append: appendRole,
    remove: removeRole,
  } = useFieldArray({
    control,
    name: "roles",
  });

  const {
    fields: userPermissionField,
    append: appendUserPermission,
    remove: removeUserPermission,
  } = useFieldArray({
    control,
    name: "users",
  });
  const {
    fields: khatabookPermissionField,
    append: appendkhatabookPermission,
    remove: removekhatabookPermission,
  } = useFieldArray({
    control,
    name: "khata_book",
  });
  const {
    fields: cashbookPermissionField,
    append: appendcashbookPermission,
    remove: removecashbookPermission,
  } = useFieldArray({
    control,
    name: "cash_book",
  });

  const {
    fields: templatePermissionField,
    append: appendTemplatePermission,
    remove: removeTemplatePermission,
  } = useFieldArray({
    control,
    name: "template",
  });

  const viewRoleInfo = (id, isEditable = false) => {
    const getEntry = JSON.parse(id);
    reset();
    setModalActions({
      ...modalActions,
      showModal: true,
      modalTitle: !isEditable ? `View Role Info` : `Edit Role Info`,
      submitButtonTitle: isEditable ? "Update" : "",
    });

    setValue("role", getEntry.role);

    permissibleKeys.map((item) =>
      setValue(
        [item][0],
        getEntry[item] !== null && typeof getEntry[item] === "object"
          ? getEntry[item]?.map((v) => v.toLowerCase())
          : []
      )
    );
  };

  const updateRoleInfo = (data) => {
    viewRoleInfo(data, true);
    setValue("id", JSON.parse(data).id);
  };

  const createOrUpdateRole = async (data) => {
    setIsDeleting(true);
    permissibleKeys.forEach((key) => {
      if (!data.hasOwnProperty(key) || !Array.isArray(data[key])) {
        data[key] = [];
      }
    });
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${
          process.env.REACT_APP_CREATE_OR_UPDATE_ROLE
        }${data.id ? `/${data.id}` : ""}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          if (modalActions?.modalTitle?.includes("Edit Role Info")) {
            toast.success("Role updated successfully", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.success("Role created successfully", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }

          reset();
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          return response;
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });
        return error;
      });
    setModalActions({
      ...modalActions,
      showModal: false,
      title: "",
    });
    setTimeout(() => setIsDeleting(false), 2000);
  };

  const confirmBeforeDeletion = (id, requestType = false) => {
    setDeleteRoleAction({
      ...deleteRoleAction,
      showConfirmationModal: true,
      idsToDelete: requestType ? selectedItems : [id],
    });
  };

  const deleteRole = async () => {
    setIsDeleting(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_DELETE_ROLE}`,
        {
          id: deleteRoleAction.idsToDelete,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          toast.success(
            checkedCheckBoxes.length > 1
              ? "Roles deleted successfully"
              : "Role deleted successfully",
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          checkAll.checked = false;
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          setDeleteRoleAction({
            ...deleteRoleAction,
            showConfirmationModal: false,
            idsToDelete: [],
          });
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });
        return error;
      });
    setTimeout(() => setIsDeleting(false), 2000);
  };

  return (
    <>
      <Head title="Roles">
        {(checkRole?.roles?.includes("delete") ||
          checkRole?.roles?.includes("Delete")) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip">
                {checkedCheckBoxes.length > 1
                  ? "Click to delete roles"
                  : "Click to delete role"}
              </Tooltip>
            }
          >
            <Button
              className="btn btn-danger btn-sm me-1"
              style={{ display: "none" }}
              id="multipleDeleteBtn"
              onClick={() => confirmBeforeDeletion("", 1)}
            >
              {checkedCheckBoxes.length > 1 ? "Delete Roles" : "Delete Role"}
            </Button>
          </OverlayTrigger>
        )}
        {(checkRole?.roles?.includes("create") ||
          checkRole?.roles?.includes("Create")) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip">Click to add new role</Tooltip>
            }
          >
            <Button
              onClick={() => {
                reset();
                setModalActions({
                  ...modalActions,
                  showModal: true,
                  modalTitle: `Create New Role`,
                  submitButtonTitle: "Create",
                });
              }}
              className="btn btn-sm btn-neutral button"
            >
              Add Role
            </Button>
          </OverlayTrigger>
        )}
      </Head>

      <div className="card border-0">
        <div className="card-body">
          <DataTables
            url={`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_FETCH_ROLES}`}
            columns={columns}
            defaultSortedColumnIndex={2}
            viewRoleInfo={viewRoleInfo}
            updateRoleInfo={updateRoleInfo}
            dispatchCallType={FETCH_ROLE_SUCCESS}
            confirmBeforeDeletion={confirmBeforeDeletion}
            setSelectedItems={setSelectedItems}
          />
        </div>
      </div>

      <Modal show={modalActions.showModal}>
        <Modal.Header
          className="border-0 text-center justify-content-center position-sticky top-0 bg-white"
          style={{ zIndex: 1050 }}
        >
          <Modal.Title as="h6">{modalActions.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-role-modal">
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Role Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Role Name"
              {...register("role")}
            />
            {errors.role && errors.role?.message !== undefined && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {errors.role?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row className="g-0">
            <Col xs={12}>
              <Form.Text className="add-role-invoice-text">
                Permissions For Invoice Management -
              </Form.Text>
            </Col>
            <Col xs={12} className="mt-3">
              <Row>
                {crudPermissionType.map((item) => (
                  <Col>
                    <Form.Check
                      type="checkbox"
                      id={`${item.toLowerCase()}-invoice-permission`}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const selectedPermission = item.toLowerCase();
                        if (isChecked) {
                          // If any other permission is selected, also select "View"
                          appendInvoices(selectedPermission);
                          if (!getValues("invoice")?.includes("view")) {
                            appendInvoices("view");
                          }
                        } else {
                          // If "View" is deselected, deselect all other permissions
                          if (selectedPermission === "view") {
                            setValue("invoice", []);
                          } else {
                            setValue(
                              "invoice",
                              getValues("invoice").filter(
                                (it) => it !== selectedPermission
                              )
                            );
                          }
                        }
                      }}
                      checked={getValues("invoice")?.includes(
                        item.toLowerCase()
                      )}
                      defaultChecked={getValues("invoice")?.includes(
                        item.toLowerCase()
                      )}
                      label={item}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="g-0 mt-2">
            <Col xs={12}>
              <Form.Text className="add-role-invoice-text">
                Permissions For Voucher Management -
              </Form.Text>
            </Col>
            <Col xs={12} className="mt-3">
              <Row>
                {crudPermissionType.map((item) => (
                  <Col>
                    <Form.Check
                      type="checkbox"
                      id={`${item.toLowerCase()}-voucher-permission`}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const selectedPermission = item.toLowerCase();
                        if (isChecked) {
                          // If any other permission is selected, also select "View"
                          appendVouchers(selectedPermission);
                          if (!getValues("vouchers")?.includes("view")) {
                            appendVouchers("view");
                          }
                        } else {
                          // If "View" is deselected, deselect all other permissions
                          if (selectedPermission === "view") {
                            setValue("vouchers", []);
                          } else {
                            setValue(
                              "vouchers",
                              getValues("vouchers").filter(
                                (it) => it !== selectedPermission
                              )
                            );
                          }
                        }
                      }}
                      checked={getValues("vouchers")?.includes(
                        item.toLowerCase()
                      )}
                      defaultChecked={getValues("vouchers")?.includes(
                        item.toLowerCase()
                      )}
                      label={item}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="g-0 mt-2">
            <Col xs={12}>
              <Form.Text className="add-role-invoice-text">
                Permissions For Role Management -
              </Form.Text>
            </Col>
            <Col xs={12} className="mt-3">
              <Row>
                {crudPermissionType.map((item) => (
                  <Col>
                    <Form.Check
                      type="checkbox"
                      id={`${item.toLowerCase()}-role-permission`}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const selectedPermission = item.toLowerCase();
                        if (isChecked) {
                          // If any other permission is selected, also select "View"
                          appendRole(selectedPermission);
                          if (!getValues("roles")?.includes("view")) {
                            appendRole("view");
                          }
                        } else {
                          // If "View" is deselected, deselect all other permissions
                          if (selectedPermission === "view") {
                            setValue("roles", []);
                          } else {
                            setValue(
                              "roles",
                              getValues("roles").filter(
                                (it) => it !== selectedPermission
                              )
                            );
                          }
                        }
                      }}
                      checked={getValues("roles")?.includes(item.toLowerCase())}
                      defaultChecked={getValues("roles")?.includes(
                        item.toLowerCase()
                      )}
                      label={item}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="g-0 mt-2">
            <Col xs={12}>
              <Form.Text className="add-role-invoice-text">
                Permission For Template Management -
              </Form.Text>
            </Col>
            <Col xs={12} className="mt-3">
              <Form.Check
                style={{ marginLeft: "14px" }}
                type="checkbox"
                id={`template-access-permission`}
                onChange={(e) =>
                  e.target.checked
                    ? appendTemplatePermission("access")
                    : removeTemplatePermission(
                        templatePermissionField.indexOf("access")
                      )
                }
                defaultChecked={getValues("template")?.includes("access")}
                label="User can access templates"
              />
            </Col>
          </Row>
          <Row className="mt-2 g-0">
            <Col xs={12}>
              <Form.Text className="add-role-invoice-text">
                Permissions For User Management -
              </Form.Text>
            </Col>
            <Col xs={12} className="mt-3">
              <Row>
                {crudPermissionType.map((item) => (
                  <Col>
                    <Form.Check
                      type="checkbox"
                      id={`${item.toLowerCase()}-user-permission`}
                      // onChange={(e) =>
                      //   e.target.checked
                      //     ? appendUserPermission(item.toLowerCase())
                      //     : setValue(
                      //         "users",
                      //         getValues("users").filter(
                      //           (it) => it !== item.toLowerCase()
                      //         )
                      //       )
                      // }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const selectedPermission = item.toLowerCase();
                        if (isChecked) {
                          // If any other permission is selected, also select "View"
                          appendUserPermission(selectedPermission);

                          if (!getValues("users")?.includes("view")) {
                            appendUserPermission("view");
                          }
                        } else {
                          // If "View" is deselected, deselect all other permissions
                          if (selectedPermission === "view") {
                            setValue("users", []);
                          } else {
                            setValue(
                              "users",
                              getValues("users").filter(
                                (it) => it !== selectedPermission
                              )
                            );
                          }
                        }
                      }}
                      checked={getValues("users")?.includes(item.toLowerCase())}
                      defaultChecked={getValues("users")?.includes(
                        item.toLowerCase()
                      )}
                      label={item}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="mt-2 g-0">
            <Col xs={12}>
              <Form.Text className="add-role-invoice-text">
                Permissions For Cash Book Management -
              </Form.Text>
            </Col>
            <Col xs={12} className="mt-3">
              <Row>
                {crudPermissionType.map((item) => (
                  <Col>
                    <Form.Check
                      type="checkbox"
                      id={`${item.toLowerCase()}-cash-permission`}
                      // onChange={(e) =>
                      //   e.target.checked
                      //     ? appendUserPermission(item.toLowerCase())
                      //     : setValue(
                      //         "users",
                      //         getValues("users").filter(
                      //           (it) => it !== item.toLowerCase()
                      //         )
                      //       )
                      // }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const selectedPermission = item.toLowerCase();
                        if (isChecked) {
                          // If any other permission is selected, also select "View"
                          appendcashbookPermission(selectedPermission);

                          if (!getValues("cash_book")?.includes("view")) {
                            appendcashbookPermission("view");
                          }
                        } else {
                          // If "View" is deselected, deselect all other permissions
                          if (selectedPermission === "view") {
                            setValue("cash_book", []);
                          } else {
                            setValue(
                              "cash_book",
                              getValues("cash_book").filter(
                                (it) => it !== selectedPermission
                              )
                            );
                          }
                        }
                      }}
                      checked={getValues("cash_book")?.includes(
                        item.toLowerCase()
                      )}
                      defaultChecked={getValues("cash_book")?.includes(
                        item.toLowerCase()
                      )}
                      label={item}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="mt-2 g-0">
            <Col xs={12}>
              <Form.Text className="add-role-invoice-text">
                Permissions For Khata Book Management -
              </Form.Text>
            </Col>
            <Col xs={12} className="mt-3">
              <Row>
                {crudPermissionType.map((item) => (
                  <Col>
                    <Form.Check
                      type="checkbox"
                      id={`${item.toLowerCase()}-khatabook-permission`}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const selectedPermission = item.toLowerCase();
                        if (isChecked) {
                          // If any other permission is selected, also select "View"
                          appendkhatabookPermission(selectedPermission);

                          if (!getValues("khata_book")?.includes("view")) {
                            appendkhatabookPermission("view");
                          }
                        } else {
                          // If "View" is deselected, deselect all other permissions
                          if (selectedPermission === "view") {
                            setValue("khata_book", []);
                          } else {
                            setValue(
                              "khata_book",
                              getValues("khata_book").filter(
                                (it) => it !== selectedPermission
                              )
                            );
                          }
                        }
                      }}
                      checked={getValues("khata_book")?.includes(
                        item.toLowerCase()
                      )}
                      defaultChecked={getValues("khata_book")?.includes(
                        item.toLowerCase()
                      )}
                      label={item}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 position-sticky bottom-0  bg-white">
          <div className="d-flex justify-content-end">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip">Click to close</Tooltip>}
            >
              <Button
                variant="secondary"
                onClick={() => {
                  setModalActions({
                    ...modalActions,
                    showModal: false,
                    title: ``,
                    submitButtonTitle: "",
                  });
                  reset();
                }}
                size="sm"
              >
                Close
              </Button>
            </OverlayTrigger>
            {modalActions.submitButtonTitle ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip">Click to create</Tooltip>}
              >
                <Button
                  variant="primary"
                  size="sm"
                  className="ms-2"
                  disabled={isDeleting}
                  onClick={handleSubmit(createOrUpdateRole)}
                >
                  {isDeleting ? (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border"
                        role="status"
                        style={{ height: "21px", width: "21px" }}
                      ></div>
                    </div>
                  ) : (
                    modalActions.submitButtonTitle
                  )}
                </Button>
              </OverlayTrigger>
            ) : null}
          </div>
        </Modal.Footer>
      </Modal>
      <ToastContainer style={{ top: "60px" }} />

      <Modal show={deleteRoleAction.showConfirmationModal} centered>
        <Modal.Header className="border-0 justify-content-center text-danger fw-bold fs-6">
          Warning!
        </Modal.Header>
        <Modal.Body
          className="text-center py-4"
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Are you want to delete the selected{" "}
          {checkedCheckBoxes.length > 1 ? "Roles" : "Role"} ?
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() =>
              setDeleteRoleAction({
                ...deleteRoleAction,
                showConfirmationModal: false,
                idsToDelete: [],
              })
            }
            size="sm"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            disabled={isDeleting}
            onClick={() => deleteRole()}
          >
            {isDeleting ? (
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ height: "21px", width: "21px" }}
                ></div>
              </div>
            ) : (
              "Confirm"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
      <ReactTooltip id="my-tooltip" />
    </>
  );
};

export default Roles;
