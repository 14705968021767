import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Table,
  Col,
  Button,
  InputGroup,
} from "react-bootstrap";
import { BsPercent } from "react-icons/bs";
import generatePDF from "react-to-pdf";
import "./Template1.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { ToastContainer } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "../AutoComplete/AutoComplete";

export default function Templates2(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const ref = React.createRef();
  const { userInfo } = useSelector((state) => state.userInfo);
  const changeTemplate =
    userInfo?.user_data?.role?.template?.includes("access");
  const { paymentDetails } = useSelector((state) => state.paymentDetails);

  const formatDate = userInfo?.account_preferences?.date_formate;
  const filterPaymentDetails = paymentDetails?.filter((row) => row?.isPrimary);
  const {
    requestType,
    isCreateInvoice,
    applyGST,
    applyDIS,
    toWords,
    addFields,
    removeFields,
    setApplyGST,
    setApplyDIS,
    disableSubmit,
    formik,
    showTemplate,
    handlePaidToChange,
    handleTemplatesChange,
    paymentDetailsEditable,
    setPaymentDetailsEditable,
  } = props;
  let subTotal = 0;
  let total = 0;
  let Quantity = 0;
  let discountVal = 0;
  let appliedgst = 0;
  let applieddiscount = 0;
  let netTotal = 0;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentRef = useRef();
  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9 || .]/.test(ch)) {
      evt.preventDefault();
    }
  }

  function numberonly1(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (parseFloat(values.has_discounts) > 0) {
      setApplyDIS(true);
    }
    if (parseFloat(values.has_gst) > 0) {
      setApplyGST(true);
    }
    setIsDataLoaded(true);
  }, []);

  useEffect(() => {
    if (state?.download === "true" && isDataLoaded) {
      document.getElementById("pdf-button").click();
      navigate("/invoices");
    }
  }, [state?.download, isDataLoaded, navigate]);

  const {
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (parseFloat(discountVal) !== parseFloat(values?.total_amount)) {
      setFieldValue("total_amount", discountVal);
    }
  }, [discountVal, applyGST, applyDIS, values]);

  function capitalizeWords(sentence) {
    // Split the sentence into words
    const words = sentence.split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words?.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the capitalized words back into a sentence
    return capitalizedWords.join(" ");
  }

  return (
    <div className="main222">
      <Card style={{ width: "800px" }}>
        <Form noValidate onSubmit={handleSubmit}>
          <div ref={componentRef}>
            <div ref={ref}>
              <Row className="d-flex justify-content-between  p-4">
                <Col className="d-flex justify-content-start ps-0" sm={6}>
                  {" "}
                  {userInfo?.account_preferences?.attached_logo ? (
                    <img
                      alt="temp"
                      src={`
                        ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
                          `}
                      style={{
                        height: "80px",
                      }}
                    />
                  ) : (
                    "{Logo}"
                  )}
                  <div className=" p-0 m-0 ps-1">
                    {" "}
                    <Form.Text
                      className="d-block m-0"
                      style={{ fontSize: "19px", color: "#f79234" }}
                    >
                      {" "}
                      IPH{" "}
                    </Form.Text>
                    <Form.Text
                      className="d-block m-0"
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#006cb5",
                      }}
                    >
                      {" "}
                      TECHNOLOGIES{" "}
                    </Form.Text>
                    <Form.Text
                      className="d-block m-0"
                      style={{ fontSize: "19px", color: "#f79234" }}
                    >
                      {" "}
                      PVT. LTD.
                    </Form.Text>
                  </div>
                </Col>
                <Col>
                  <div>
                    <Form.Text
                      className="d-block mt-0 text-end "
                      style={{ fontSize: "35px" }}
                    >
                      INVOICE
                    </Form.Text>
                    {!isCreateInvoice && (
                      <Form.Text
                        className="text11 p-0 "
                        style={{ lineHeight: "1.2", marginTop: "-6px" }}
                      >
                        {values?.invoice_no}
                      </Form.Text>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="d-flex justify-content-between px-4">
                <Col sm={6} className="ps-0">
                  <div
                    className={`${
                      requestType === "edit" || isCreateInvoice ? "" : ""
                    } m-0`}
                    style={{
                      paddingBottom:
                        requestType === "edit" || isCreateInvoice ? "" : "0px",
                    }}
                  >
                    <Form.Text
                      className="text111 text-start"
                      style={{ whiteSpace: "" }}
                    >
                      PROJECT NAME:
                    </Form.Text>
                    {requestType === "edit" || isCreateInvoice ? (
                      <AutoComplete
                        autoComplete="off"
                        requestType="project_name"
                        // callbackFieldValue={handleProjectNameChange}
                        fieldValue={(values.project_name || "").replace(
                          /&amp;/g,
                          "&"
                        )}
                        className="mb-2 w-100"
                        name="project_name"
                        id="project_name"
                        placeholder="Enter Project Name"
                        readOnly={!(requestType === "edit" || isCreateInvoice)}
                      />
                    ) : (
                      <Form.Text
                        className="text4 text-start mt-0"
                        style={{ width: "100%" }}
                      >
                        {values?.project_name}
                      </Form.Text>
                    )}
                    {touched.project_name && errors.project_name && (
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {errors.project_name}
                      </Form.Control.Feedback>
                    )}
                  </div>

                  <Form.Text className="text111 text-start mt-0">
                    BILL TO:
                  </Form.Text>
                  {requestType === "edit" || isCreateInvoice ? (
                    <AutoComplete
                      requestType="bills"
                      searchingFor="paid_to"
                      callbackFieldValue={handlePaidToChange}
                      fieldValue={(values.paid_to || "").replace(/&amp;/g, "&")}
                      className="w-100 mt-1 "
                      name="paid_to"
                      id="paid_to"
                      placeholder="Enter Company Name"
                      readOnly={!(requestType === "edit" || isCreateInvoice)}
                    />
                  ) : (
                    <Form.Text
                      className="text4   text-start mt-0"
                      style={{ width: "50%" }}
                    >
                      {values?.paid_to}
                    </Form.Text>
                  )}
                  {touched.paid_to && errors.paid_to && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block mt-0 "
                      style={{ marginLeft: "13px" }}
                    >
                      {errors.paid_to}
                    </Form.Control.Feedback>
                  )}

                  {requestType === "edit" || isCreateInvoice ? (
                    <AutoComplete
                      requestType="bills"
                      searchingFor="address"
                      name="address"
                      callbackFieldValue={(e) =>
                        setFieldValue("address", e.target.value)
                      }
                      fieldValue={(values.address || "").replace(/&amp;/g, "&")}
                      as="textarea"
                      rows={1}
                      required
                      className="mt-1 w-100"
                      placeholder="Enter Address"
                      readOnly={!(requestType === "edit" || isCreateInvoice)}
                    />
                  ) : (
                    <Form.Text
                      className="text4  text-start mt-0"
                      style={{ width: "100%" }}
                    >
                      {values?.address}
                    </Form.Text>
                  )}
                  {touched.address && errors.address && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block my-0 "
                      style={{ marginLeft: "13px" }}
                    >
                      {errors.address}
                    </Form.Control.Feedback>
                  )}
                </Col>

                <Col sm={6} className="px-0 mt-0">
                  <div className="">
                    <Form.Text className="text111 me-3">DATE:</Form.Text>

                    {requestType === "edit" || isCreateInvoice ? (
                      <div className="text-start flex-grow-1 mt-0">
                        <DatePicker
                          // wrapperClassName="datewrapper"
                          customInput={
                            <Form.Control
                              aria-describedby="basic-addon1"
                              className="my-1 text-end w-100"
                            />
                          }
                          required
                          maxDate={new Date()}
                          name="date"
                          id="date"
                          // change
                          dateFormat={
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                                  ?.replace(/D/g, "d")
                                  ?.replace(/Y/g, "y")
                          }
                          selected={
                            values?.date ? new Date(values?.date) : null
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "date",
                              e ? moment(e).format("YYYY-MM-DD") : ""
                            );
                          }}
                          readOnly={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        />
                        {touched.date && errors.date && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block mt-0"
                          >
                            {errors.date}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    ) : (
                      <Form.Text className="text11 mt-0 me-3">
                        {moment(values?.date).format(
                          `${
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                          }`
                        )}
                      </Form.Text>
                    )}
                  </div>
                  <Form.Text className="text111 mt-0 pe-3">FROM:</Form.Text>
                  <Row className="text-end fixedbottom">
                    {" "}
                    <Form.Text
                      className="text11 mt-0"
                      style={{ lineHeight: "1.2" }}
                    >
                      {userInfo?.account_preferences?.address ||
                      userInfo?.account_preferences?.state ||
                      userInfo?.account_preferences?.pincode
                        ? `${userInfo?.account_preferences?.address}, ${userInfo?.account_preferences?.city}, ${userInfo?.account_preferences?.state} -  ${userInfo?.account_preferences?.pincode}`
                        : "{Company address - pincode}"}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.contact_no
                        ? `${userInfo?.account_preferences?.contact_no}`
                        : "{Contact number}"}
                      {userInfo?.account_preferences?.alt_contact_no
                        ? ` ${userInfo?.account_preferences?.alt_contact_no},`
                        : ""}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.email_address
                        ? `${userInfo?.account_preferences?.email_address}`
                        : ", {Email address}"}
                    </Form.Text>
                  </Row>
                </Col>
              </Row>

              <div className="mx-4 ">
                <Table striped hover className="mt-4">
                  <thead>
                    <tr>
                      <th
                        className="text9"
                        style={{ backgroundColor: "#f7b731", width: "140px" }}
                      >
                        Date
                      </th>
                      <th
                        className="text9"
                        style={{ backgroundColor: "#f7b731" }}
                      >
                        Details
                      </th>
                      <th
                        className="text9"
                        style={{ backgroundColor: "#f7b731", width: "120px" }}
                      >
                        Item Price
                      </th>
                      <th
                        className="text9"
                        style={{ backgroundColor: "#f7b731", width: "110px" }}
                      >
                        {values.item_unit}
                      </th>
                      <th
                        className="text9"
                        style={{ backgroundColor: "#f7b731", width: "140px" }}
                      >
                        Payments
                      </th>

                      {(requestType === "edit" ||
                        (isCreateInvoice &&
                          values?.voucher_descriptions?.length > 1)) && (
                        <th
                          className="text9"
                          style={{
                            backgroundColor: "#f7b731",
                          }}
                        ></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {values.voucher_descriptions?.map((row, index) => {
                      total +=
                        row.quantity || row.item_price
                          ? parseFloat(row.quantity * row.item_price)
                          : 0;
                      subTotal = total;
                      Quantity += row.quantity ? parseFloat(row.quantity) : 0;
                      applieddiscount =
                        (subTotal * (values?.has_discounts || 0)) / 100;
                      netTotal = (
                        parseFloat(total) -
                        parseFloat(applyDIS ? applieddiscount : 0)
                      ).toFixed(2);

                      appliedgst = applyDIS
                        ? (netTotal * (values?.has_gst || 0)) / 100
                        : (subTotal * (values?.has_gst || 0)) / 100;

                      discountVal = (
                        parseFloat(subTotal) +
                        parseFloat(applyGST ? appliedgst : 0) -
                        parseFloat(applyDIS ? applieddiscount : 0)
                      ).toFixed(2);
                      discountVal = applyDIS
                        ? (
                            parseFloat(netTotal) +
                            parseFloat(applyGST ? appliedgst : 0)
                          ).toFixed(2)
                        : (
                            parseFloat(subTotal) +
                            parseFloat(applyGST ? appliedgst : 0)
                          ).toFixed(2);
                      return (
                        <tr
                          key={index}
                          style={{ height: "10px" }}
                          className="temp1row"
                        >
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <DatePicker
                                wrapperClassName="datewrapper"
                                customInput={
                                  <Form.Control
                                    aria-describedby="basic-addon1"
                                    className="w-100"
                                  />
                                }
                                required
                                maxDate={new Date()}
                                name="date"
                                id="date"
                                // change
                                dateFormat={
                                  formatDate === null ||
                                  formatDate === undefined
                                    ? "dd MMM, yyyy"
                                    : formatDate
                                        ?.replace(/D/g, "d")
                                        ?.replace(/Y/g, "y")
                                }
                                selected={
                                  values.voucher_descriptions[index]?.item_date
                                    ? new Date(
                                        values.voucher_descriptions[
                                          index
                                        ].item_date
                                      )
                                    : null
                                }
                                onChange={(date) => {
                                  setFieldValue(
                                    `voucher_descriptions.${index}.item_date`,
                                    date
                                      ? moment(date).format("YYYY-MM-DD")
                                      : ""
                                  );
                                }}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_date`
                                )}
                              >
                                {moment(
                                  getFieldProps(
                                    `voucher_descriptions.${index}.item_date`
                                  ).value
                                ).format(
                                  `${
                                    formatDate === null ||
                                    formatDate === undefined
                                      ? "DD MMM, YYYY"
                                      : formatDate
                                  }`
                                )}
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]?.item_date &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]?.item_date && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_date}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                                required
                                as="textarea"
                                name="item_description"
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_description`
                                )}
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_description`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `voucher_descriptions.${index}.item_description`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]
                                ?.item_description &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]
                                ?.item_description && (
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_description}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                maxLength={7}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                                onKeyPress={numberonly}
                                name="item_price"
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_price`
                                )}
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_price`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `voucher_descriptions.${index}.item_price`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]?.item_price &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]
                                ?.item_price && (
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_price}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                maxLength={4}
                                required
                                type="text"
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                                onKeyPress={numberonly1}
                                name="quantity"
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.quantity`
                                )}
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.quantity`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `voucher_descriptions.${index}.quantity`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]?.quantity &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]?.quantity && (
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.quantity}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                onKeyPress={numberonly}
                                value={(row.item_price * row.quantity).toFixed(
                                  2
                                )}
                                rows={1}
                                readOnly
                                name="Payments"
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                              >
                                {(row.item_price * row.quantity).toFixed(2)}
                              </Form.Text>
                            )}
                          </td>
                          {(requestType === "edit" ||
                            (isCreateInvoice &&
                              values?.voucher_descriptions?.length > 1)) && (
                            <td className="text-center fs-5 pt-3">
                              <AiOutlineClose
                                className="text-danger mt-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeFields(index, row?.id)}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={4}
                        style={{
                          borderWidth: "",
                          padding: "1px 4px 4px 150px",
                        }}
                      >
                        {" "}
                        <Row className="d-flex align-items-center  mb-1">
                          <Col className="px-0">
                            <Form.Text className="text111 text-start">
                              Sub Total
                            </Form.Text>
                          </Col>
                        </Row>
                        {!(
                          requestType === "view" &&
                          !isCreateInvoice &&
                          !values?.has_discounts
                        ) && (
                          <Row
                            style={{ display: "flex", alignItem: "baseline" }}
                          >
                            <Col className="ps-0">
                              {requestType === "view" && (
                                <Form.Label
                                  className="sizewt"
                                  style={{ color: "#454056", opacity: "0.5" }}
                                >
                                  Apply Discount
                                </Form.Label>
                              )}
                              {requestType !== "view" && (
                                <Form.Group controlId="applyDIS" md="auto">
                                  <Form.Check
                                    className="sizewt text-start"
                                    disabled={
                                      !(
                                        requestType === "edit" ||
                                        isCreateInvoice
                                      )
                                    }
                                    checked={applyDIS}
                                    onChange={() => setApplyDIS(!applyDIS)}
                                    type="checkbox"
                                    label="Apply Discount"
                                  />
                                </Form.Group>
                              )}
                            </Col>
                            {applyDIS && (
                              <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                                <div className="d-flex flex-row sizewt align-items-center pb-1">
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <Form.Control
                                      className="gstdis shadow-none sizewt flex-row align-items-center"
                                      maxLength={3}
                                      type="number"
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      value={values?.has_discounts}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const nonNegativeValue =
                                          parseFloat(inputValue) < 0
                                            ? 0
                                            : inputValue;
                                        setFieldValue(
                                          "has_discounts",
                                          nonNegativeValue
                                        );
                                      }}
                                      name="disctval"
                                      style={{
                                        border:
                                          requestType === "edit" ||
                                          isCreateInvoice
                                            ? "1px solid #666276"
                                            : "none",
                                        padding: "0.2rem",
                                        right: "5px",
                                        width: "40px",
                                        height: "1.45em",
                                        fontSize: "14px",
                                        textAlign: "end",
                                        borderRadius: "6px",
                                        marginRight: "-15px",
                                      }}
                                    />
                                  ) : (
                                    <Form.Text
                                      style={{
                                        fontSize: "15px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      {values?.has_discounts}
                                    </Form.Text>
                                  )}
                                </div>
                                <div
                                  className="d-flex align-items-center flex-row sizewt pb-1"
                                  style={{ marginLeft: "5px" }}
                                >
                                  {" "}
                                  <BsPercent className="" />
                                  &nbsp;
                                </div>
                              </Col>
                            )}
                          </Row>
                        )}
                        {applyDIS && (
                          <Row
                            className="d-flex align-items-center mb-1"
                            style={{ marginTop: "-3px" }}
                          >
                            <Col className="px-0">
                              <Form.Text className="text111 text-start">
                                Net Total
                              </Form.Text>
                            </Col>
                          </Row>
                        )}
                        {!(
                          requestType === "view" &&
                          !isCreateInvoice &&
                          !values?.has_gst
                        ) && (
                          <Row
                            style={{ display: "flex", alignItem: "baseline" }}
                          >
                            <Col className="ps-0">
                              {requestType === "view" && (
                                <Form.Label
                                  className="sizewt"
                                  style={{ color: "#454056", opacity: "0.5" }}
                                >
                                  Apply GST
                                </Form.Label>
                              )}
                              {requestType !== "view" && (
                                <Form.Group controlId="ApplyGST" md="auto">
                                  <Form.Check
                                    className="sizewt text-start"
                                    checked={applyGST}
                                    onChange={() => setApplyGST(!applyGST)}
                                    type="checkbox"
                                    label="Apply GST"
                                    disabled={
                                      !(
                                        requestType === "edit" ||
                                        isCreateInvoice
                                      )
                                    }
                                  />
                                </Form.Group>
                              )}
                            </Col>
                            {applyGST && (
                              <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                                <div className="d-flex flex-row sizewt align-items-center">
                                  {requestType === "edit" || isCreateInvoice ? (
                                    <Form.Control
                                      className="gstdis shadow-none sizewt flex-row align-items-center"
                                      name="has_gst"
                                      maxLength={5}
                                      type="number"
                                      value={values?.has_gst}
                                      readOnly={
                                        !(
                                          requestType === "edit" ||
                                          isCreateInvoice
                                        )
                                      }
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const nonNegativeValue =
                                          parseFloat(inputValue) < 0
                                            ? 0
                                            : inputValue;
                                        setFieldValue(
                                          "has_gst",
                                          nonNegativeValue
                                        );
                                      }}
                                      style={{
                                        border:
                                          requestType === "edit" ||
                                          isCreateInvoice
                                            ? "1px solid #666276"
                                            : "none",
                                        padding: "0.2rem",
                                        right: "5px",
                                        width: "40px",
                                        height: "1.45em",
                                        fontSize: "14px",
                                        textAlign: "end",
                                        borderRadius: "6px",
                                        marginRight: "-15px",
                                      }}
                                    />
                                  ) : (
                                    <Form.Text
                                      style={{
                                        fontSize: "15px",
                                        marginTop: "0px",
                                      }}
                                    >
                                      {values?.has_gst}
                                    </Form.Text>
                                  )}
                                </div>
                                <div
                                  className="d-flex align-items-center  flex-row sizewt pb-1"
                                  style={{ marginLeft: "5px" }}
                                >
                                  {" "}
                                  <BsPercent className="" />
                                  &nbsp;
                                </div>
                              </Col>
                            )}
                          </Row>
                        )}
                      </td>
                      <td
                        colSpan={
                          values.voucher_descriptions?.length > 1 ? 2 : 1
                        }
                        style={{
                          verticalAlign: "top",
                          paddingTop: "6px",
                        }}
                      >
                        <Row className="mb-1" style={{ width: "100%" }}>
                          <Col className="text-start p-0">
                            <Form.Text className="sizewt">
                              {`₹${total.toFixed(2)}`}
                            </Form.Text>
                          </Col>
                        </Row>
                        {applyDIS && (
                          <Row
                            style={{
                              width: "100%",
                              marginTop: applyGST ? "4px" : "7px",
                            }}
                          >
                            <Col className="text-start p-0">
                              <Form.Text className="sizewt">
                                {`₹${applieddiscount.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                        )}
                        <Row className="mb-1">
                          <Col
                            className="text-start p-0"
                            style={{ marginTop: applyDIS ? "6px" : "0" }}
                          >
                            {applyDIS && (
                              <Form.Text className="sizewt">
                                {`₹${netTotal}`}
                              </Form.Text>
                            )}
                          </Col>
                        </Row>
                        {applyGST && (
                          <Row
                            style={{
                              width: "100%",
                              marginTop: applyDIS ? "7px" : "1px",
                            }}
                          >
                            <Col className="text-start p-0">
                              <Form.Text className="sizewt">
                                {`₹${appliedgst.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={4}
                        style={{
                          paddingLeft: "145px",
                          backgroundColor: "#f7b731",
                        }}
                      >
                        <Row className="d-flex align-items-center">
                          <Col className="px-0">
                            <Form.Text className="total1">Total</Form.Text>
                          </Col>
                        </Row>
                      </td>
                      <td
                        colSpan={
                          values.voucher_descriptions?.length > 1 ? 2 : 1
                        }
                        style={{ backgroundColor: "#f7b731" }}
                      >
                        <Row className="">
                          <Col className="text-start p-0">
                            <Form.Text
                              className="total1 "
                              style={{ textAlign: "start" }}
                            >
                              {`₹${parseFloat(discountVal).toFixed(2)}`}
                            </Form.Text>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
                <Row className="mt-1">
                  <Col sm={6} className="ps-0">
                    <div style={{ maxWidth: "300px" }}>
                      <div className="d-flex  justify-content-between">
                        <Form.Text className="text111 text-start p-0 my-1">
                          Payment Details :
                        </Form.Text>
                        {requestType === "edit" ||
                          (isCreateInvoice && (
                            <Button
                              size="sm"
                              onClick={() => setPaymentDetailsEditable(true)}
                              variant="light"
                              className="text-primary"
                            >
                              Edit
                            </Button>
                          ))}
                      </div>
                      {!(requestType === "view" && !isCreateInvoice) &&
                        filterPaymentDetails
                          ?.sort((a, b) => b?.mode - a?.mode)
                          ?.map((payment) => (
                            <Form.Check
                              inline
                              label={capitalizeWords(payment?.mode)}
                              checked={values.payment_Details
                                ?.map((row) => row?.mode)
                                ?.includes(payment?.mode)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue("payment_Details", [
                                    ...values.payment_Details,
                                    payment,
                                  ]);
                                } else {
                                  setFieldValue(
                                    "payment_Details",
                                    values.payment_Details?.filter(
                                      (row) => row?.mode !== payment?.mode
                                    )
                                  );
                                }
                              }}
                              type="checkbox"
                              disabled={
                                !(requestType === "edit" || isCreateInvoice)
                              }
                            />
                          ))}
                      {touched.payment_Details && errors.payment_Details && (
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors?.payment_Details}
                        </Form.Control.Feedback>
                      )}
                      {values?.payment_Details?.length > 0
                        ? values?.payment_Details?.map((payment, index) => {
                            return (
                              <>
                                <div
                                  style={{
                                    borderBottom:
                                      index < values.payment_Details.length - 1
                                        ? "1px solid #efecf3"
                                        : "none",
                                    paddingBottom:
                                      index < values.payment_Details.length - 1
                                        ? "7px"
                                        : "none",
                                    marginLeft: "2px",
                                    marginBottom: "7px",
                                  }}
                                >
                                  {payment?.mode === "bank" && (
                                    <>
                                      <Form.Text className="text4">
                                        <b>Bank Name:</b> {payment?.bank_name}
                                      </Form.Text>
                                      <Form.Text className="text4">
                                        <b>Account Name:</b>{" "}
                                        {payment?.accountName}
                                      </Form.Text>
                                      <Form.Text className="text4 ">
                                        <b>Account Number:</b>{" "}
                                        {payment?.accountNumber}
                                      </Form.Text>
                                      <Form.Text className="text4 ">
                                        <b>IFSC code:</b> {payment?.ifscCode}
                                      </Form.Text>
                                      {payment?.swiftCode && (
                                        <Form.Text className="text4 ">
                                          <b>Swift Code:</b>{" "}
                                          {payment?.swiftCode}
                                        </Form.Text>
                                      )}
                                    </>
                                  )}
                                  {/* UPI and Paypal Details */}
                                  {(payment?.mode === "UPI" ||
                                    payment?.mode === "Paypal") && (
                                    <>
                                      <Form.Text className="text4  text-start">
                                        <b>{payment?.mode} Id:</b>{" "}
                                        {payment?.paymentID}
                                      </Form.Text>
                                    </>
                                  )}
                                  {/* Other Details */}
                                  {payment?.mode === "other" && (
                                    <>
                                      <Form.Text className="text4 ">
                                        <b>Title:</b> {payment?.title}
                                      </Form.Text>
                                      <Form.Text className="text4 ">
                                        <b>Description:</b>{" "}
                                        {payment?.discription}
                                      </Form.Text>
                                    </>
                                  )}
                                </div>
                              </>
                            );
                          })
                        : null}{" "}
                    </div>
                  </Col>
                  <Col sm={6} className="px-0">
                    {values.other_info && (
                      <InputGroup className="">
                        <Form.Text className="text111 text-start p-0 my-1">
                          Other Details:
                        </Form.Text>
                        {requestType === "edit" || isCreateInvoice ? (
                          <AutoComplete
                            wrapperClassName={
                              isCreateInvoice ? "w-100" : "w-100"
                            }
                            requestType="bills"
                            searchingFor="other_info"
                            callbackFieldValue={(e) =>
                              setFieldValue("other_info", e.target.value)
                            }
                            fieldValue={values.other_info}
                            as="textarea"
                            rows={3}
                            className="w-100 rounded"
                            name="otherInfo"
                            id="otherInfo"
                            aria-describedby="basic-addon1"
                            placeholder="Enter Other Details"
                            readOnly={
                              !(requestType === "edit" || isCreateInvoice)
                            }
                          />
                        ) : (
                          <Form.Text
                            className="text4  text-start"
                            style={{ width: "100%" }}
                          >
                            {values?.other_info}
                          </Form.Text>
                        )}
                      </InputGroup>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {!showTemplate && (
            <Row className=" position-sticky bottom-0 p-2 mt-5 mb-3">
              {requestType === "edit" || isCreateInvoice ? (
                <>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          {changeTemplate
                            ? "Click to change template"
                            : "No access to change template"}
                        </Tooltip>
                      }
                    >
                      <Button
                        style={{
                          width: "100%",
                          backgroundColor: "#FD5631",
                          pointerEvents: "auto",
                        }}
                        // variant="secondary"
                        onClick={() => {
                          if (requestType === "edit") {
                            handleTemplatesChange(true);
                          } else {
                            navigate("/invoiceTemplate", {
                              state: {
                                values: formik?.values,
                                requestType: requestType || isCreateInvoice,
                              },
                            });
                          }
                        }}
                        disabled={!changeTemplate}
                      >
                        Change Template
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to add one row below
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        style={{ width: "100%" }}
                        onClick={addFields}
                      >
                        Add Row
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to submit the data to all invoices
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        style={{ width: "100%" }}
                        type="submit"
                        disabled={disableSubmit}
                      >
                        {disableSubmit ? (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border"
                              role="status"
                              style={{ height: "21px", width: "21px" }}
                            ></div>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </OverlayTrigger>
                    <ToastContainer style={{ top: "60px" }} />
                  </Col>
                </>
              ) : (
                <>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to print the data in PDF
                        </Tooltip>
                      }
                    >
                      <Button
                        // className="hide"
                        variant="secondary"
                        style={{
                          width: "100%",
                          marginLeft: "3px",
                          // backgroundColor: "#006cb5",
                        }}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to download the PDF
                        </Tooltip>
                      }
                    >
                      <Button
                        id="pdf-button"
                        onClick={() => {
                          generatePDF(componentRef, {
                            filename: `${values?.invoice_no}.pdf`,
                          });
                        }}
                        variant="secondary"
                        style={{
                          width: "100%",
                          marginRight: "3px",
                        }}
                      >
                        Download
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          {changeTemplate
                            ? "Click to change template"
                            : "No access to change template"}
                        </Tooltip>
                      }
                    >
                      <Button
                        // variant="secondary"
                        style={{
                          width: "100%",
                          backgroundColor: "#FD5631",
                          pointerEvents: "auto",
                        }}
                        onClick={() => {
                          if (!(requestType === "edit" || isCreateInvoice)) {
                            handleTemplatesChange(true);
                          } else {
                            navigate("/invoiceTemplate", {
                              state: {
                                values: formik?.values,
                                requestType: requestType || isCreateInvoice,
                              },
                            });
                          }
                          // handleTemplatesChange(true);
                        }}
                        disabled={!changeTemplate}
                      >
                        Change Template
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </>
              )}
            </Row>
          )}
        </Form>{" "}
      </Card>
    </div>
  );
}
