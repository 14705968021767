import "../App.css";
import { Badge, Button, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Head from "../components/NavBar/Head";
import { BsDownload, BsEye, BsPen, BsTrash3 } from "react-icons/bs";
import { renderToStaticMarkup } from "react-dom/server";
import DataTables from "../components/DataTables";
import axios from "axios";
import { IoDuplicateOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import moment from "moment";
import Modals from "../components/Modal";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { paymentModes } from "../Utils/Constants";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Tooltip as ReactTooltip } from "react-tooltip";

import {
  DateRangePicker,
  createStaticRanges,
  defaultStaticRanges,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import MultiRange from "../components/CustomRangeSlider/MultiRange";
import { FETCH_VOUCHER_SUCCESS } from "../store/voucher/VoucherActionTypes";
import InvoiceVoucherModal from "../Utils/InvoiceVoucherModal";
import SelectVouchers from "../components/Voucher/SelectVouchers";
import VoucherTemplates from "../components/Voucher/VoucherTemplates";

const AllVouchers = ({
  isCashBook = false,
  callBackHandleChange,
  cashBookAmount,
  cashBookDate,
  VoucherNumberNotLinked = false,
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [additionalFilter, setAdditionalFilter] = useState(
    isCashBook ? { nearbyAmount: cashBookAmount } : {}
  );
  const { theme } = useSelector((state) => state);
  const { userInfo } = useSelector((state) => state.userInfo);
  const { vouchers } = useSelector((state) => state.vouchers);
  const checkRole = userInfo?.user_data?.role;
  const [selectedItems, setSelectedItems] = useState([]);
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const [isShow, setIsShow] = useState({
    open: false,
    id: "",
    requestType: "",
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const formatDate = userInfo?.account_preferences?.date_formate;
  const [dateRange, setDateRange] = useState([
    {
      startDate: isCashBook ? new Date(cashBookDate) : new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const checkAll = document.querySelector(".checkAll");
  const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setAdditionalFilter({
      ...additionalFilter,
      dateRange: `${moment(ranges.selection.startDate).format(
        "YYYY-MM-DD"
      )} | ${moment(ranges.selection.endDate).format("YYYY-MM-DD")}`,
    });
  };

  const [deleteVoucherAction, setDeleteVoucherAction] = useState({
    showConfirmationModal: false,
  });

  const columns = [
    {
      data: "id",
      class: "d-none",
    },
    {
      data: null,
      title:
        '<input class="form-check-input checkAll" type="checkbox" data-action="checkAll">',
      width: "20px",
      class: "text-center align-middle",
      orderable: false,

      render: function (data, type, row) {
        return (
          `<input type="checkbox" class="form-check-input checkBox" name="posts[]" value="` +
          row["id"] +
          `" data-action="checkBox">`
        );
      },
    },
    {
      data: "paid_to",
      title: "Paid To",
      class: "text-left align-middle",
    },
    {
      data: "date",
      class: "text-left align-middle",
      title: "Voucher Date",
      // width: "101px",
      render: function (data, type, row) {
        return moment(row.date).format(
          `${
            formatDate === null || formatDate === undefined
              ? "DD MMM, YYYY"
              : formatDate
          }`
        );
      },
    },
    {
      data: "invoice_no",
      class: "text-left align-middle",
      title: "Voucher Number",
      // width:"151px"
    },
    {
      data: "payment_mode",
      class: "text-left align-middle",
      title: "Payment Mode",
      // width:"112px"
    },
    {
      data: "total_amount",
      class: "text-left align-middle",

      title: "Amount",
      render: function (data, type, row) {
        return `₹${row?.total_amount}`;
      },
    },
    {
      data: null,
      class: "text-left  align-middle",
      title: "Actions",
      width: isCashBook ? "140px" : "250px",
      orderable: false,
      render: function (data, type, row) {
        if (isCashBook && row?.cashbook_attachment_id !== null)
          return renderToStaticMarkup(
            <Badge bg="warning" className="fw-bold m-2">
              Attached
            </Badge>
          );
        if (isCashBook)
          return renderToStaticMarkup(
            <div className="text-right">
              {(checkRole?.vouchers?.includes("view") ||
                checkRole?.vouchers?.includes("View")) && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip">Click to Add Voucher</Tooltip>
                  }
                >
                  <Button
                    variant="primary"
                    size="sm"
                    data-id={row?.id}
                    data-action="handleCallBackChange"
                    className=" btn-sm "
                  >
                    Add Voucher{" "}
                  </Button>
                </OverlayTrigger>
              )}
            </div>
          );
        return renderToStaticMarkup(
          <div className="text-right">
            {(checkRole?.vouchers?.includes("view") ||
              checkRole?.vouchers?.includes("View")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="View voucher"
                variant="light"
                size="sm"
                data-id={row.id}
                data-action="handleView"
                className="btn-icon mx-1"
                onClick="handleButtonClick('sdf')"
              >
                <BsEye size={15} />
              </Button>
            )}
            {(checkRole?.vouchers?.includes("edit") ||
              checkRole?.vouchers?.includes("Edit")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Edit voucher"
                variant="light"
                size="sm"
                className="btn-icon mx-1"
                data-id={row.id}
                data-action="handleEdit"
              >
                <BsPen size={15} />
              </Button>
            )}
            {(checkRole?.vouchers?.includes("create") ||
              checkRole?.vouchers?.includes("Create")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Create duplicate voucher"
                variant="light"
                size="sm"
                data-id={row.id}
                data-action="handleDuplicate"
                className="btn-icon mx-1"
              >
                <IoDuplicateOutline size={15} />
              </Button>
            )}
            {(checkRole?.vouchers?.includes("view") ||
              checkRole?.vouchers?.includes("View")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Download voucher"
                variant="light"
                size="sm"
                className="me-2 btn-icon mx-1"
                data-id={row.id}
                data-action="downloadAsPdf"
              >
                <BsDownload size={15} />
              </Button>
            )}

            {(checkRole?.vouchers?.includes("delete") ||
              checkRole?.vouchers?.includes("Delete")) && (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Delete voucher"
                variant="light"
                size="sm"
                className="btn-icon text-danger mx-1"
                data-id={row.id}
                data-action="confirmBeforeDeletion"
              >
                <BsTrash3 size={15} />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleView = (id) => {
    setIsShow({
      ...isShow,
      open: true,
      id,
      requestType: "view",
    });
  };
  const handleEdit = (id) => {
    setIsShow({
      ...isShow,
      open: true,
      id,
      requestType: "edit",
      needTemplateChange: false,
    });
  };
  const downloadAsPdf = (id) => {
    navigate(`view/${id}`, {
      state: {
        download: "true",
        templateId: "download",
      },
    });
  };
  const handleDuplicate = (id, requestType = false) => {
    navigate(
      `/vouchers/create-duplicate-voucher/${
        requestType ? selectedItems[0] : id
      }`
    );
  };
  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };
  const confirmBeforeDeletion = (id, requestType = false) => {
    const getEntry = requestType
      ? vouchers?.filter((item) =>
          selectedItems?.includes(item.id?.toString())
        ) || []
      : vouchers?.filter((item) => item.id === parseInt(id)) || [];

    const connectedVoucherId =
      getEntry
        ?.filter(
          (row) =>
            typeof row?.cashbook_attachment_id === "object" &&
            typeof row?.cashbook_attachment_id?.attached_with_voucher ==
              "number"
        )
        ?.map((key) =>
          key?.cashbook_attachment_id?.attached_with_voucher?.toString()
        ) || [];

    setDeleteVoucherAction({
      ...deleteVoucherAction,
      showConfirmationModal: true,
      idsToDelete: requestType ? selectedItems : [id],
      connectedVoucherId,
    });
  };

  const handleCallBackChange = (id) => {
    const voucherAmount = parseFloat(
      vouchers?.find((item) => item.id === parseInt(id, 10))?.total_amount
    );
    callBackHandleChange(id, voucherAmount);
  };
  const getVoucherData = async () => {
    const transformDate = (date) =>
      moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
    const transformData = (data) => {
      const responseData = [...data];
      return responseData?.map((row) => ({
        ...row,
        date: transformDate(row.date),
      }));
    };
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_VOUCHER_LIST}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: FETCH_VOUCHER_SUCCESS,
            data: transformData(res?.data?.data),
          });
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
        }
        return res;
      })
      .catch((error) => error);
  };
  const deleteVoucher = async () => {
    setIsDeleting(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_DELETE_VOUCHER}`,
        {
          ids: deleteVoucherAction.idsToDelete,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then(async (res) => {
        if (res?.status === 200) {
          if (deleteVoucherAction?.connectedVoucherId?.length > 0) {
            await deleteCashbookEntries();
          } else {
            setDeleteVoucherAction({
              ...deleteVoucherAction,
              showConfirmationModal: false,
              idsToDelete: [],
              connectedVoucherId: [],
            });
            checkAll.checked = false;
            toast.success(
              checkedCheckBoxes.length > 1
                ? "Vouchers deleted successfully"
                : "Voucher deleted successfully",
              {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
            getVoucherData();
            setTimeout(() => setIsDeleting(false), 2000);
          }
        }
      })
      .catch((error) => {
        setIsDeleting(false);
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });

        return error;
      });
  };

  const deleteCashbookEntries = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_DELETE_CASHBOOK_ENTRIES}`,
        {
          id: deleteVoucherAction?.connectedVoucherId,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      if (res.status === 200) {
        checkAll.checked = false;
        toast.success(
          checkedCheckBoxes.length > 1
            ? "Vouchers deleted successfully"
            : "Voucher deleted successfully",
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setTimeout(() => {
          setDeleteVoucherAction({
            ...deleteVoucherAction,
            showConfirmationModal: false,
            idsToDelete: [],
            connectedVoucherId: [],
          });
          getVoucherData();
          setIsDeleting(false);
        }, 4000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const staticRanges = [
    ...defaultStaticRanges,
    ...createStaticRanges([
      {
        key: "thisYear",
        label: "This Financial Year",
        range: () => {
          const now = new Date();
          const startYear =
            now.getMonth() >= 3 ? now.getFullYear() : now.getFullYear() - 1;
          return {
            startDate: new Date(startYear, 3, 1), // Start in April
            endDate: new Date(startYear + 1, 2, 31), // End in March
          };
        },
      },
      {
        key: "pastYear",
        label: "Past Financial Year",
        range: () => {
          const now = new Date();
          const startYear =
            now.getMonth() >= 3 ? now.getFullYear() - 1 : now.getFullYear() - 2;
          return {
            startDate: new Date(startYear, 3, 1), // Start in April of the previous year
            endDate: new Date(startYear + 1, 2, 31), // End in March of the previous year
          };
        },
      },
    ]),
  ].splice(2, 12);

  return (
    <>
      <Head title="All Vouchers">
        {!isCashBook && (
          <>
            {(checkRole?.vouchers?.includes("delete") ||
              checkRole?.vouchers?.includes("Delete")) && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip">
                    {checkedCheckBoxes.length > 1
                      ? "Click to delete the vouchers"
                      : "Click to delete the voucher"}
                  </Tooltip>
                }
              >
                <Button
                  className="btn btn-danger btn-sm me-1"
                  style={
                    checkedCheckBoxes.length
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  id="multipleDeleteBtn"
                  onClick={() => confirmBeforeDeletion("", 1)}
                >
                  {checkedCheckBoxes.length > 1
                    ? "Delete Vouchers"
                    : "Delete Voucher"}
                </Button>
              </OverlayTrigger>
            )}
            {(checkRole?.vouchers?.includes("create") ||
              checkRole?.vouchers?.includes("Create")) && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip">
                    Click to Create Duplicate Voucher
                  </Tooltip>
                }
              >
                <Button
                  variant="primary"
                  className="btn-sm me-1"
                  style={
                    checkedCheckBoxes.length === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  id="duplicateVoucher"
                  onClick={() => handleDuplicate("", 1)}
                >
                  Create Duplicate Voucher
                </Button>
              </OverlayTrigger>
            )}
          </>
        )}
        {(checkRole?.vouchers?.includes("create") ||
          checkRole?.vouchers?.includes("Create")) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip">Click to create new Voucher</Tooltip>
            }
          >
            <Button
              variant={isCashBook ? "success" : "primary"}
              className="btn-sm"
              onClick={() => {
                if (
                  isCashBook &&
                  VoucherNumberNotLinked !== null &&
                  VoucherNumberNotLinked !== undefined &&
                  VoucherNumberNotLinked
                ) {
                  navigate("/create-new-voucher", {
                    state: { voucherNumber: VoucherNumberNotLinked },
                  });
                } else {
                  navigate("/create-new-voucher");
                }
              }}
            >
              Create New Voucher
            </Button>
          </OverlayTrigger>
        )}
      </Head>
      <Card className="border-0">
        <Card.Header className="row d-flex justify-content-around align-items-center g-0">
          <Col>Filters</Col>

          <Col xs="auto" className="me-3 position-relative">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">Click select amount range</Tooltip>
              }
            >
              <Form.Select
                size="sm"
                aria-label="Default select example"
                onChange={(e) =>
                  setAdditionalFilter({
                    ...additionalFilter,
                    requestEvent: e.target.value,
                    amountRange: e.target.value,
                  })
                }
              >
                <option value="">Amount Range</option>
                <option value="0 | 1000">Less Then ₹1,000</option>
                <option value="1000 | 2500">Between ₹1,000 - ₹2,500</option>
                <option value="2500 | 5000">Between ₹2,500 - ₹5,000</option>
                <option value="5000 | 10000">Between ₹5,000 - ₹10,000</option>
                <option value="custom">Custom Range</option>
              </Form.Select>
            </OverlayTrigger>
          </Col>

          {additionalFilter?.requestEvent === "custom" ? (
            <Col xs="auto" className="me-4 ms-2">
              <MultiRange
                min={200}
                max={2000}
                amountRange={(value) =>
                  setAdditionalFilter({
                    ...additionalFilter,
                    amountRange: `${value?.ranges[1]} | ${value?.ranges[2]}`,
                  })
                }
              />
            </Col>
          ) : null}
          <Col xs="auto" className="me-3">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">
                  Click to select payment mode
                </Tooltip>
              }
            >
              <Form.Select
                size="sm"
                aria-label="Default select example"
                onChange={(e) =>
                  setAdditionalFilter({
                    ...additionalFilter,
                    paymentMode: e.target.value,
                  })
                }
              >
                <option value="">Payment Mode</option>
                {paymentModes.map((items) => (
                  <option value={items}>{items}</option>
                ))}
              </Form.Select>
            </OverlayTrigger>
          </Col>
          <Col xs="auto" className=" position-relative">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">
                  Click to select date range
                </Tooltip>
              }
            >
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  size="sm"
                >
                  {`${moment(dateRange[0].startDate).format(
                    `${
                      formatDate === null || formatDate === undefined
                        ? "DD MMM, YYYY"
                        : formatDate
                    }`
                  )} - ${moment(dateRange[0].endDate).format(
                    `${
                      formatDate === null || formatDate === undefined
                        ? "DD MMM, YYYY"
                        : formatDate
                    }`
                  )}`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0 overflow-hidden">
                  <Dropdown.Item className="p-0 ">
                    <DateRangePicker
                      ranges={dateRange}
                      onChange={handleSelect}
                      editableDateInputs={true}
                      staticRanges={staticRanges}
                      maxDate={new Date()}
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </OverlayTrigger>
          </Col>
        </Card.Header>
        <Card.Body>
          <DataTables
            url={`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_VOUCHER_LIST}`}
            columns={columns}
            defaultSortedColumnIndex={3}
            downloadAsPdf={downloadAsPdf}
            dispatchCallType={FETCH_VOUCHER_SUCCESS}
            confirmBeforeDeletion={confirmBeforeDeletion}
            setSelectedItems={setSelectedItems}
            additionalFilter={additionalFilter}
            handleCallBackChange={handleCallBackChange}
            handleDuplicate={handleDuplicate}
            noOrder={isCashBook}
            handleView={handleView}
            handleEdit={handleEdit}
          />
        </Card.Body>
        {isShow.requestType !== "edit" && !isCashBook && (
          <ToastContainer style={{ top: "60px" }} />
        )}{" "}
      </Card>
      <Modal show={deleteVoucherAction.showConfirmationModal} centered>
        <Modal.Header className="border-0 justify-content-center text-danger fw-bold fs-6">
          Warning!
        </Modal.Header>
        <Modal.Body
          className="text-center py-4"
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {selectedItems.length > 1
            ? " Are you sure you want to delete the selected vouchers?"
            : "Are you sure you want to delete the selected voucher?"}
          {deleteVoucherAction?.connectedVoucherId?.length > 0 &&
            `\n On deleting this, the attached cashbook will also be deleted.`}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="secondary"
            onClick={() =>
              setDeleteVoucherAction({
                ...deleteVoucherAction,
                showConfirmationModal: false,
                idsToDelete: [],
                connectedVoucherId: [],
              })
            }
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="primary"
            disabled={isDeleting}
            onClick={deleteVoucher}
          >
            {isDeleting ? (
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border"
                  role="status"
                  style={{ height: "21px", width: "21px" }}
                ></div>
              </div>
            ) : (
              "Confirm"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
      <InvoiceVoucherModal
        isShow={isShow}
        setIsShow={setIsShow}
        setFor="voucher"
        body={
          isShow?.needTemplateChange ? (
            <VoucherTemplates setIsShow={setIsShow} isShow={isShow} />
          ) : (
            <SelectVouchers
              theme={
                isShow?.values?.template_id
                  ? isShow?.values?.template_id
                  : theme.VoucherTemplate
              }
              id={isShow?.id}
              requestType={isShow?.requestType}
              setIsShow={setIsShow}
              isShow={isShow}
              getVoucherData={getVoucherData}
            />
          )
        }
      />
      <ReactTooltip id="my-tooltip" />
    </>
  );
};

export default AllVouchers;
