import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Table,
  Col,
  Button,
  InputGroup,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { BsPercent } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import generatePDF from "react-to-pdf";
import { useReactToPrint } from "react-to-print";
import { ToastContainer } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AiOutlineClose } from "react-icons/ai";
import AutoComplete from "../AutoComplete/AutoComplete";

export default function VoucherTemplate1(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const ref = React.createRef();
  const { userInfo } = useSelector((state) => state.userInfo);
  const changeTemplate =
    userInfo?.user_data?.role?.template?.includes("access");
  const formatDate = userInfo?.account_preferences?.date_formate;

  const {
    requestType,
    isCreateVoucher,
    toWords,
    addFields,
    removeFields,
    disableSubmit,
    formik,
    applyGST,
    applyDIS,
    setApplyGST,
    setApplyDIS,
    handlePaidToChange,
    fromCashBook = false,
    handleUnlinkVoucher,
    handleTemplatesChange,
  } = props;
  let subTotal = 0;
  let total = 0;
  let Quantity = 0;
  let discountVal = 0;
  let appliedgst = 0;
  let applieddiscount = 0;
  let netTotal = 0;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const componentRef = useRef();
  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9 || .]/.test(ch)) {
      evt.preventDefault();
    }
  }

  function numberonly1(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (parseFloat(values.has_discounts) > 0) {
      setApplyDIS(true);
    }
    if (parseFloat(values.has_gst) > 0) {
      setApplyGST(true);
    }
    setIsDataLoaded(true);
  }, []);

  useEffect(() => {
    if (state?.download === "true" && isDataLoaded) {
      document.getElementById("pdf-button").click();
      navigate("/vouchers");
    }
  }, [state?.download, isDataLoaded, navigate]);

  const {
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (parseFloat(discountVal) !== parseFloat(values?.total_amount)) {
      setFieldValue("total_amount", discountVal);
    }
  }, [discountVal, applyGST, applyDIS, values]);

  return (
    <div className="main222">
      <Card
        className={fromCashBook ? "border-0" : ""}
        style={{ width: "800px" }}
      >
        <Form noValidate onSubmit={handleSubmit}>
          <div
            ref={componentRef}
            style={{ paddingTop: fromCashBook ? "0px" : "20px" }}
          >
            <div ref={ref}>
              <div className="m-4 pb-2 " style={{ border: "2px solid black" }}>
                <Row className="mt-2">
                  <Col sm={6} className="pt-3">
                    {" "}
                    <div>
                      {userInfo?.account_preferences?.attached_logo ? (
                        <img
                          alt="temp"
                          src={`
                              ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
                            `}
                          style={{
                            height: "60px",
                          }}
                        />
                      ) : (
                        "{Logo}"
                      )}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Form.Text className="text111 text-start d-inline">
                      Address: &nbsp;
                    </Form.Text>
                    <Form.Text className="text11 text-start d-inline">
                      {userInfo?.account_preferences?.address
                        ? `${userInfo?.account_preferences?.address}, ${userInfo?.account_preferences?.city}, ${userInfo?.account_preferences?.pincode}`
                        : "{Company address, City, Pincode}"}
                    </Form.Text>
                    <br />
                    <Form.Text className="text111 text-start d-inline">
                      Email: &nbsp;
                    </Form.Text>
                    <Form.Text className="text11 text-start d-inline">
                      {userInfo?.account_preferences?.email_address
                        ? userInfo?.account_preferences?.email_address
                        : "{Email address}"}
                    </Form.Text>
                    <br />
                    <Form.Text className="text111 text-start d-inline">
                      Contact: &nbsp;
                    </Form.Text>
                    <Form.Text className="text11 text-start d-inline">
                      {userInfo?.account_preferences?.contact_no
                        ? `${userInfo?.account_preferences?.contact_no}`
                        : "{Contact number}"}
                      {userInfo?.account_preferences?.alt_contact_no
                        ? `, ${userInfo?.account_preferences?.alt_contact_no}`
                        : ""}
                    </Form.Text>
                  </Col>
                </Row>
                <div
                  style={{
                    backgroundColor: "#d5d2dc",
                    height: "1px",
                    marginTop: "15px",
                  }}
                ></div>
                <h6 className="my-2 text-center ">Payment Voucher</h6>
                <div
                  style={{
                    backgroundColor: "#d5d2dc",
                    height: "1px",
                    marginTop: "10px",
                  }}
                ></div>
                <div className="mx-2">
                  <div className="row">
                    {!isCreateVoucher && (
                      <div className="col-md-6 col-sm-6 ps-0 pe-2 mt-2 ">
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className=" text33"
                            style={{ width: "100px" }}
                          >
                            Voucher
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder="Enter Voucher No."
                            required
                            value={values?.voucher_no}
                            readOnly
                          />
                        </InputGroup>
                      </div>
                    )}
                    <div
                      className={
                        !isCreateVoucher
                          ? "col-md-6 col-sm-6 ps-0 pe-0 mt-2 "
                          : "col-md-12 col-sm-12 ps-0 pe-0 mt-2 "
                      }
                    >
                      <>
                        <DatePicker
                          className="w-100"
                          required
                          disabled={
                            !(requestType === "edit" || isCreateVoucher)
                          }
                          name="date"
                          id="date"
                          format={
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                                  ?.replace(/D/g, "d")
                                  ?.replace(/Y/g, "y")
                          }
                          selected={
                            values?.date ? new Date(values?.date) : null
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "date",
                              e ? moment(e).format("YYYY-MM-DD") : ""
                            );
                          }}
                          maxDate={new Date()}
                          {...props}
                          customInput={
                            <InputGroup>
                              <InputGroup.Text
                                style={{ width: "100px" }}
                                id="basic-addon1"
                                className=" text33"
                              >
                                Date
                              </InputGroup.Text>

                              <Form.Control
                                aria-describedby="basic-addon1"
                                value={moment(values?.date).format(
                                  `${
                                    formatDate === null ||
                                    formatDate === undefined
                                      ? "DD MMM, YYYY"
                                      : formatDate
                                  }`
                                )}
                              />
                            </InputGroup>
                          }
                        />
                        {touched.date && errors.date && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block mb-1"
                          >
                            {errors.date}
                          </Form.Control.Feedback>
                        )}
                      </>
                    </div>
                  </div>
                  <InputGroup className={isCreateVoucher ? "mt-3" : ""}>
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ width: "100px" }}
                      className="text33"
                    >
                      Paid To
                    </InputGroup.Text>{" "}
                    <AutoComplete
                      wrapperClassName={
                        isCreateVoucher
                          ? "wrapper-custom-width"
                          : "wrapper-custom-width-2"
                      }
                      requestType="bills"
                      searchingFor="paid_to"
                      callbackFieldValue={handlePaidToChange}
                      fieldValue={(values.paid_to || "").replace(/&amp;/g, "&")}
                      className="w-100 rounded-start-0"
                      name="paid_to"
                      id="paid_to"
                      aria-describedby="basic-addon1"
                      placeholder="Paid To"
                      readOnly={!(requestType === "edit" || isCreateVoucher)}
                      token={userInfo?.token}
                    />
                  </InputGroup>
                  {touched?.paid_to && errors?.paid_to && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block mb-1"
                    >
                      {errors.paid_to}
                    </Form.Control.Feedback>
                  )}
                  <InputGroup className="mt-3">
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ width: "100px" }}
                      className="text33"
                    >
                      Address
                    </InputGroup.Text>
                    <AutoComplete
                      wrapperClassName={
                        isCreateVoucher
                          ? "wrapper-custom-width"
                          : "wrapper-custom-width-2"
                      }
                      requestType="bills"
                      searchingFor="address"
                      callbackFieldValue={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length > 255) {
                          alert("Address length is too long");
                          return;
                        }
                        setFieldValue("address", inputValue);
                      }}
                      fieldValue={(values.address || "").replace(/&amp;/g, "&")}
                      className="w-100 rounded-start-0"
                      name="address"
                      id="address"
                      aria-describedby="basic-addon1"
                      placeholder="Address"
                      as="textarea"
                      rows={1}
                      readOnly={!(requestType === "edit" || isCreateVoucher)}
                      token={userInfo?.token}
                    />
                  </InputGroup>
                  {touched?.address && errors?.address && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block mb-1"
                    >
                      {errors?.address}
                    </Form.Control.Feedback>
                  )}
                  <Table striped hover className="mt-3">
                    <thead>
                      <tr style={{ borderWidth: "1px" }}>
                        <th className="text9" style={{ borderWidth: "1px" }}>
                          Details
                        </th>
                        <th className="text9" style={{ width: "120px" }}>
                          Item Price
                        </th>
                        <th
                          className="text9"
                          style={{ width: "120px", borderWidth: "1px" }}
                        >
                          Quantity
                        </th>
                        <th
                          className="text9"
                          style={{
                            borderWidth: "1px",
                            width: "140px",
                          }}
                        >
                          Payments
                        </th>

                        {(requestType === "edit" || isCreateVoucher) &&
                          values?.bill_description.length > 1 && (
                            <th className="text9 text-end"></th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {values.bill_description?.map((row, index) => {
                        total +=
                          row.quantity || row.item_price
                            ? parseFloat(row.quantity * row.item_price)
                            : 0;
                        subTotal = total;
                        Quantity += row.quantity ? parseFloat(row.quantity) : 0;

                        applieddiscount =
                          (subTotal * (values?.has_discounts || 0)) / 100;
                        netTotal = (
                          parseFloat(total) -
                          parseFloat(applyDIS ? applieddiscount : 0)
                        ).toFixed(2);

                        appliedgst = applyDIS
                          ? (netTotal * (values?.has_gst || 0)) / 100
                          : (subTotal * (values?.has_gst || 0)) / 100;

                        discountVal = applyDIS
                          ? (
                              parseFloat(netTotal) +
                              parseFloat(applyGST ? appliedgst : 0)
                            ).toFixed(2)
                          : (
                              parseFloat(subTotal) +
                              parseFloat(applyGST ? appliedgst : 0)
                            ).toFixed(2);

                        return (
                          <tr
                            key={index}
                            style={{ height: "10px", borderWidth: "1px" }}
                          >
                            <td>
                              {requestType === "edit" || isCreateVoucher ? (
                                <AutoComplete
                                  requestType="billdescriptions"
                                  searchingFor="item_description"
                                  name={`bill_description.${index}.item_description`}
                                  callbackFieldValue={(e) =>
                                    setFieldValue(
                                      `bill_description.${index}.item_description`,
                                      e.target.value
                                    )
                                  }
                                  fieldValue={row.item_description}
                                  as="textarea"
                                  rows={1}
                                  required
                                  readOnly={
                                    !(requestType === "edit" || isCreateVoucher)
                                  }
                                  token={userInfo?.token}
                                />
                              ) : (
                                <Form.Text
                                  className="text11  text-start"
                                  style={{ width: "100%" }}
                                  {...getFieldProps(
                                    `bill_description.${index}.item_description`
                                  )}
                                >
                                  {
                                    getFieldProps(
                                      `bill_description.${index}.item_description`
                                    ).value
                                  }
                                </Form.Text>
                              )}
                              {touched.bill_description &&
                                touched.bill_description[index]
                                  ?.item_description &&
                                errors.bill_description &&
                                errors.bill_description[index]
                                  ?.item_description && (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {errors.bill_description &&
                                      errors.bill_description[index]
                                        ?.item_description}
                                  </Form.Control.Feedback>
                                )}
                            </td>
                            <td style={{ borderWidth: "1px" }}>
                              {requestType === "edit" || isCreateVoucher ? (
                                <Form.Control
                                  maxLength={7}
                                  required
                                  rows={1}
                                  readOnly={
                                    !(requestType === "edit" || isCreateVoucher)
                                  }
                                  onKeyPress={numberonly}
                                  name="item_price"
                                  {...getFieldProps(
                                    `bill_description.${index}.item_price`
                                  )}
                                  type="text"
                                />
                              ) : (
                                <Form.Text
                                  className="text11  text-start"
                                  style={{ width: "100%" }}
                                  {...getFieldProps(
                                    `bill_description.${index}.item_price`
                                  )}
                                >
                                  {
                                    getFieldProps(
                                      `bill_description.${index}.item_price`
                                    ).value
                                  }
                                </Form.Text>
                              )}
                              {touched.bill_description &&
                                touched.bill_description[index]?.item_price &&
                                errors.bill_description &&
                                errors.bill_description[index]?.item_price && (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {errors.bill_description &&
                                      errors.bill_description[index]
                                        ?.item_price}
                                  </Form.Control.Feedback>
                                )}
                            </td>
                            <td style={{ borderWidth: "1px" }}>
                              {requestType === "edit" || isCreateVoucher ? (
                                <Form.Control
                                  maxLength={4}
                                  required
                                  readOnly={
                                    !(requestType === "edit" || isCreateVoucher)
                                  }
                                  onKeyPress={numberonly1}
                                  name="quantity"
                                  {...getFieldProps(
                                    `bill_description.${index}.quantity`
                                  )}
                                  type="text"
                                />
                              ) : (
                                <Form.Text
                                  className="text11  text-start"
                                  style={{ width: "100%" }}
                                  {...getFieldProps(
                                    `bill_description.${index}.quantity`
                                  )}
                                >
                                  {
                                    getFieldProps(
                                      `bill_description.${index}.quantity`
                                    ).value
                                  }
                                </Form.Text>
                              )}
                              {touched.bill_description &&
                                touched.bill_description[index]?.quantity &&
                                errors.bill_description &&
                                errors.bill_description[index]?.quantity && (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {errors.bill_description &&
                                      errors.bill_description[index]?.quantity}
                                  </Form.Control.Feedback>
                                )}
                            </td>
                            <td style={{ borderWidth: "1px" }}>
                              {requestType === "edit" || isCreateVoucher ? (
                                <Form.Control
                                  onKeyPress={numberonly}
                                  value={(
                                    row.item_price * row.quantity
                                  ).toFixed(2)}
                                  rows={1}
                                  readOnly
                                  name="Payments"
                                  type="text"
                                />
                              ) : (
                                <Form.Text
                                  className="text11  text-start"
                                  style={{ width: "100%" }}
                                >
                                  {(row.item_price * row.quantity).toFixed(2)}
                                </Form.Text>
                              )}
                            </td>
                            {(requestType === "edit" || isCreateVoucher) &&
                              values?.bill_description.length > 1 && (
                                <td className="text-center fs-5 ">
                                  <AiOutlineClose
                                    className="text-danger"
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "12px",
                                    }}
                                    onClick={() => removeFields(index, row?.id)}
                                  />
                                </td>
                              )}
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          colSpan={3}
                          style={{
                            borderWidth: "1px",
                            padding: "1px 4px 4px 150px",
                          }}
                        >
                          <Row className="d-flex align-items-center mb-1">
                            <Col className="px-0">
                              <Form.Text className="text111 text-start">
                                Sub Total
                              </Form.Text>
                            </Col>
                          </Row>

                          {!(
                            requestType === "view" &&
                            !isCreateVoucher &&
                            !values?.has_discounts
                          ) && (
                            <Row
                              style={{ display: "flex", alignItem: "baseline" }}
                            >
                              <Col className="ps-0">
                                {requestType === "view" && (
                                  <Form.Label
                                    className="sizewt"
                                    style={{ color: "#454056", opacity: "0.5" }}
                                  >
                                    Apply Discount
                                  </Form.Label>
                                )}
                                {requestType !== "view" && (
                                  <Form.Group controlId="applyDIS" md="auto">
                                    <Form.Check
                                      className="sizewt text-start"
                                      disabled={
                                        !(
                                          requestType === "edit" ||
                                          isCreateVoucher
                                        )
                                      }
                                      checked={applyDIS}
                                      onChange={() => setApplyDIS(!applyDIS)}
                                      type="checkbox"
                                      label="Apply Discount"
                                    />
                                  </Form.Group>
                                )}
                              </Col>
                              {applyDIS && (
                                <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                                  <div className="d-flex flex-row sizewt align-items-center">
                                    {requestType === "edit" ||
                                    isCreateVoucher ? (
                                      <Form.Control
                                        className="gstdis shadow-none sizewt flex-row align-items-center"
                                        maxLength={3}
                                        type="number"
                                        readOnly={
                                          !(
                                            requestType === "edit" ||
                                            isCreateVoucher
                                          )
                                        }
                                        value={values?.has_discounts}
                                        // onChange={(e) => {
                                        //   setFieldValue(
                                        //     "has_discounts",
                                        //     e.target.value
                                        //   );
                                        // }}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          const nonNegativeValue =
                                            parseFloat(inputValue) < 0
                                              ? 0
                                              : inputValue;
                                          setFieldValue(
                                            "has_discounts",
                                            nonNegativeValue
                                          );
                                        }}
                                        name="disctval"
                                        style={{
                                          border: "1px solid #666276",
                                          padding: "0.2rem",
                                          right: "5px",
                                          width: "60px",
                                          height: "1.45em",
                                          fontSize: "14px",
                                          textAlign: "end",
                                          borderRadius: "6px",
                                        }}
                                      />
                                    ) : (
                                      <Form.Text
                                        className="text-muted mt-0"
                                        style={{ fontSize: "15px" }}
                                      >
                                        {values?.has_discounts}
                                      </Form.Text>
                                    )}
                                  </div>
                                  <div
                                    className="d-flex align-items-center flex-row sizewt pb-1"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {" "}
                                    <BsPercent className="" />
                                    &nbsp;
                                    {requestType !== "view" && (
                                      <span>&nbsp;=</span>
                                    )}
                                  </div>
                                </Col>
                              )}
                            </Row>
                          )}
                          {applyDIS && (
                            <Row
                              className="d-flex align-items-center mb-1"
                              style={{ marginTop: "-3px" }}
                            >
                              <Col className="px-0">
                                <Form.Text className="text111 text-start">
                                  Net Total
                                </Form.Text>
                              </Col>
                            </Row>
                          )}

                          {!(
                            requestType === "view" &&
                            !isCreateVoucher &&
                            !values?.has_gst
                          ) && (
                            <Row
                              style={{ display: "flex", alignItem: "baseline" }}
                            >
                              <Col className="ps-0">
                                {requestType === "view" && (
                                  <Form.Label
                                    className="sizewt"
                                    style={{ color: "#454056", opacity: "0.5" }}
                                  >
                                    Apply GST
                                  </Form.Label>
                                )}
                                {requestType !== "view" && (
                                  <Form.Group controlId="ApplyGST" md="auto">
                                    <Form.Check
                                      className="sizewt text-start"
                                      checked={applyGST}
                                      onChange={() => setApplyGST(!applyGST)}
                                      type="checkbox"
                                      label="Apply GST"
                                      disabled={
                                        !(
                                          requestType === "edit" ||
                                          isCreateVoucher
                                        )
                                      }
                                    />
                                  </Form.Group>
                                )}
                              </Col>
                              {applyGST && (
                                <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                                  <div
                                    className="d-flex flex-row sizewt align-items-center"
                                    style={{ marginTop: "-0.45rem" }}
                                  >
                                    {requestType === "edit" ||
                                    isCreateVoucher ? (
                                      <Form.Control
                                        className="gstdis shadow-none sizewt flex-row align-items-center"
                                        name="has_gst"
                                        maxLength={5}
                                        type="number"
                                        value={values?.has_gst}
                                        readOnly={
                                          !(
                                            requestType === "edit" ||
                                            isCreateVoucher
                                          )
                                        }
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          const nonNegativeValue =
                                            parseFloat(inputValue) < 0
                                              ? 0
                                              : inputValue;
                                          setFieldValue(
                                            "has_gst",
                                            nonNegativeValue
                                          );
                                        }}
                                        style={{
                                          border: "1px solid #666276",
                                          padding: "0.2rem",
                                          right: "5px",
                                          width: "60px",
                                          height: "1.45em",
                                          fontSize: "14px",
                                          textAlign: "end",
                                          borderRadius: "6px",
                                        }}
                                      />
                                    ) : (
                                      <Form.Text
                                        className="text-muted mt-0"
                                        style={{ fontSize: "15px" }}
                                      >
                                        {values?.has_gst}
                                      </Form.Text>
                                    )}
                                  </div>
                                  <div
                                    className="d-flex align-items-center  flex-row sizewt pb-1"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {" "}
                                    <BsPercent className="" />
                                    &nbsp;
                                    {requestType !== "view" && (
                                      <span>&nbsp;=</span>
                                    )}
                                  </div>
                                </Col>
                              )}
                            </Row>
                          )}
                        </td>
                        <td
                          colSpan={
                            values.voucher_descriptions?.length > 1 ? 2 : 2
                          }
                          style={{
                            verticalAlign: "top",
                            paddingTop: "6px",
                            borderWidth: "1px",
                          }}
                        >
                          <Row
                            className=""
                            style={{ width: "100%", marginTop: "0px" }}
                          >
                            <Col className="text-start p-0">
                              <Form.Text className="sizewt">
                                {`₹${total.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                          {applyDIS && (
                            <Row
                              style={{
                                width: "100%",
                                marginTop: applyGST ? "4px" : "4px",
                              }}
                            >
                              <Col className="text-start p-0">
                                <Form.Text className="sizewt">
                                  {`₹${applieddiscount.toFixed(2)}`}
                                </Form.Text>
                              </Col>
                            </Row>
                          )}
                          <Row className="mb-1">
                            <Col
                              className="text-start p-0"
                              style={{ marginTop: applyDIS ? "5px" : "30px" }}
                            >
                              {applyDIS && (
                                <Form.Text className="sizewt">
                                  {`₹${netTotal}`}
                                </Form.Text>
                              )}
                            </Col>
                          </Row>
                          {applyGST && (
                            <Row
                              style={{
                                width: "100%",
                                marginTop: applyDIS
                                  ? "5px"
                                  : requestType !== "view"
                                  ? "0px"
                                  : "-30px",
                              }}
                            >
                              <Col className="text-start p-0">
                                <Form.Text className="sizewt">
                                  {`₹${appliedgst.toFixed(2)}`}
                                </Form.Text>
                              </Col>
                            </Row>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={3}
                          style={{ paddingLeft: "145px", borderWidth: "1px" }}
                        >
                          <Row className="d-flex align-items-center">
                            <Col className="px-0">
                              <Form.Text className="total3">Total</Form.Text>
                            </Col>
                          </Row>
                        </td>
                        <td
                          colSpan={
                            values.voucher_descriptions?.length > 1 ? 2 : 2
                          }
                          style={{ borderWidth: "1px" }}
                        >
                          <Row className="">
                            <Col className="p-0">
                              <Form.Text
                                className="total3"
                                style={{ textAlign: "" }}
                              >
                                {`₹${parseFloat(discountVal).toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                  <Row className="mt-1">
                    <Col sm={6} className="ps-0 mb-1">
                      <Form.Text className="text111 mb-1 text-start p-0">
                        Mode of Payment:
                      </Form.Text>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip">
                            Click to select the payment mode
                          </Tooltip>
                        }
                      >
                        {requestType === "edit" || isCreateVoucher ? (
                          <Form.Select
                            className=""
                            style={{
                              maxWidth: "240px",
                              // fontWeight: "400",
                              fontSize: "15px",
                              marginBottom: "10px",
                            }}
                            required
                            name="payment_mode"
                            value={values?.payment_mode}
                            onChange={(e) => {
                              setFieldValue("payment_mode", e.target.value);
                            }}
                            disabled={
                              !(requestType === "edit" || isCreateVoucher)
                            }
                          >
                            <option value="">Select Payment Mode</option>
                            <option value="Bank">Bank</option>
                            <option value="Cash">Cash</option>
                            <option value="Card">Card</option>
                            <option value="Cheque">Cheque</option>
                            <option value="UPI">UPI</option>
                          </Form.Select>
                        ) : (
                          <Form.Text
                            className="text4 border-0   text-start"
                            style={{ width: "100%" }}
                          >
                            {values?.payment_mode}{" "}
                          </Form.Text>
                        )}
                      </OverlayTrigger>
                      {touched.payment_mode && errors.payment_mode && (
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.payment_mode}
                        </Form.Control.Feedback>
                      )}
                      {values?.payment_mode !== "Cash" &&
                        values?.payment_mode !== "" && (
                          <Col className="">
                            <Form.Text className="text111 text-start p-0 mb-1">
                              {values?.payment_mode === "Bank"
                                ? "Transaction Id:"
                                : values?.payment_mode === "Cheque"
                                ? "Cheque Number:"
                                : values?.payment_mode === "Card"
                                ? "Enter last 4 digits of your card:"
                                : values?.payment_mode === "UPI"
                                ? "UPI Reference Id:"
                                : ""}
                            </Form.Text>
                            {requestType === "edit" || isCreateVoucher ? (
                              <Form.Control
                                style={{ maxWidth: "240px" }}
                                maxLength={25}
                                required
                                name="payment_mode_info"
                                readOnly={
                                  !(requestType === "edit" || isCreateVoucher)
                                }
                                value={values.payment_mode_info}
                                onChange={(e) => {
                                  setFieldValue(
                                    "payment_mode_info",
                                    e.target.value
                                  );
                                }}
                                placeholder={
                                  values?.payment_mode === "Bank"
                                    ? "Enter the Transaction Id:-"
                                    : values?.payment_mode === "Cash"
                                    ? "Enter Amount:-"
                                    : values?.payment_mode === "Cheque"
                                    ? "Enter Cheque Number:-"
                                    : values?.payment_mode === "Card"
                                    ? "Enter last 4 digits of your card:-"
                                    : values?.payment_mode === "UPI"
                                    ? "Enter UPI Reference Id:-"
                                    : ""
                                }
                              />
                            ) : (
                              <Form.Text
                                className="text4 border-0   text-start"
                                style={{ width: "100%" }}
                              >
                                {values && values?.payment_mode_info}
                              </Form.Text>
                            )}
                            {touched.payment_mode_info &&
                              errors.payment_mode_info && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.payment_mode_info}
                                </Form.Control.Feedback>
                              )}
                          </Col>
                        )}
                    </Col>
                    <Col className="d-flex mb-2 ps-0 p-0" sm={6}>
                      {!(
                        requestType === "view" &&
                        !isCreateVoucher &&
                        !values?.note
                      ) && (
                        <div style={{ width: "100%" }}>
                          <Form.Text className="text111 mb-1 text-start ">
                            Notes:
                          </Form.Text>{" "}
                          {requestType === "edit" || isCreateVoucher ? (
                            <AutoComplete
                              as="textarea"
                              rows={1}
                              requestType="bills"
                              searchingFor="note"
                              callbackFieldValue={(e) =>
                                setFieldValue("note", e.target.value)
                              }
                              fieldValue={values.note}
                              className="w-100"
                              name="note"
                              id="note"
                              placeholder="Write something here"
                              readOnly={
                                !(requestType === "edit" || isCreateVoucher)
                              }
                              token={userInfo?.token}
                            />
                          ) : (
                            <Form.Text
                              className="text4 border-0 text-start"
                              style={{ width: "100%" }}
                            >
                              {values && values?.note}
                            </Form.Text>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
                {requestType === "view" && !isCreateVoucher && (
                  <div style={{ marginTop: "100px" }}>
                    <Row>
                      <Col>
                        <h6>
                          Authorized By :
                          <div
                            style={{
                              marginLeft: "130px",
                              borderBottom: "2px solid #333",
                            }}
                          ></div>
                        </h6>
                      </Col>
                      <Col>
                        <h6>
                          Recieved By:
                          <div
                            style={{
                              marginLeft: "110px",
                              borderBottom: "2px solid #333",
                            }}
                          ></div>
                        </h6>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Row className=" position-sticky bottom-0 bg-white p-2 m-3">
            {requestType === "edit" || isCreateVoucher ? (
              <>
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to add one row below
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="secondary"
                      style={{ width: "100%" }}
                      onClick={addFields}
                    >
                      Add Row
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to submit the data to all vouchers
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      type="submit"
                      disabled={disableSubmit}
                    >
                      {disableSubmit ? (
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border"
                            role="status"
                            style={{ height: "21px", width: "21px" }}
                          ></div>
                        </div>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </OverlayTrigger>
                  <ToastContainer style={{ top: "60px" }} />
                </Col>
              </>
            ) : (
              <>
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to print the data in PDF
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="secondary"
                      className=" w-100"
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        // backgroundColor: "#5e58d1",
                      }}
                      onClick={handlePrint}
                    >
                      Print
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to download the data in PDF
                      </Tooltip>
                    }
                  >
                    <Button
                      id="pdf-button"
                      onClick={() => {
                        generatePDF(componentRef, {
                          filename: `${values?.voucher_no}.pdf`,
                        });
                      }}
                      variant={fromCashBook ? "secondary" : "secondary"}
                      style={{
                        width: "100%",
                        marginRight: "3px",
                      }}
                    >
                      Download
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        {changeTemplate
                          ? "Click to change template"
                          : "No access to change template"}
                      </Tooltip>
                    }
                  >
                    <Button
                      // variant="secondary"
                      style={{
                        width: "100%",
                        backgroundColor: "#FD5631",
                        pointerEvents: "auto",
                      }}
                      onClick={() => {
                        if (!(requestType === "edit" || isCreateVoucher)) {
                          handleTemplatesChange(true);
                        } else {
                          navigate("/voucherTemplate", {
                            state: {
                              values: formik?.values,
                              requestType: requestType || isCreateVoucher,
                            },
                          });
                        }
                      }}
                      disabled={!changeTemplate}
                    >
                      Change Template
                    </Button>
                  </OverlayTrigger>
                </Col>
                {fromCashBook && (
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to unlink the voucher from cashbook
                        </Tooltip>
                      }
                    >
                      <Button
                        id="pdf-button"
                        onClick={() => {
                          handleUnlinkVoucher();
                        }}
                        variant="primary"
                        style={{
                          width: "100%",
                          marginRight: "3px",
                        }}
                      >
                        Unlink
                      </Button>
                    </OverlayTrigger>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Form>
      </Card>
    </div>
  );
}
