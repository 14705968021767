import React, { useState, useEffect } from "react";
import "./Template1.css";
import { Modal, Button, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Modals from "../Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { SET_DEFAULT_INVOICE_TEMPLATE } from "../../store/Theme/ThemeActionTypes";
import Head from "../NavBar/Head";
import { ToastContainer, toast } from "react-toastify";

const templates = [
  // {
  //   id: 1,
  //   title: "Template 1",
  //   isSelected: false,
  //   image: "/templates/template1.png",
  //   showImage: "/templates/template1.png",
  // },
  // {
  //   id: 4,
  //   title: "Template 3",
  //   isSelected: false,
  //   image: "/templates/template3.png",
  //   showImage: "/templates/template3.png",
  // },
  {
    id: 3,
    title: "Template 2",
    isSelected: false,
    image: "/images/template2img.png",
    showImage: "/images/template2img.png",
  },

  // Added New Template
  {
    id: 5,
    title: "Template 6",
    isSelected: false,
    image: "/images/template1img.png",
    showImage: "/images/template1img.png",
  },
  // {
  //   id: 7,
  //   title: "Template 4",
  //   isSelected: false,
  //   image: "/images/img4.png",
  //   showImage: "/images/image4.png",
  // },
  // {
  //   id: 6,
  //   title: "Template 5",
  //   isSelected: false,
  //   image: "/images/img5.png",
  //   showImage: "/images/image5.png",
  // },
  // {
  //   id: 5,
  //   title: "Template 6",
  //   isSelected: false,
  //   image: "/images/img6.png",
  //   showImage: "/images/image6.png",
  // },
];

function AllTemplate(props) {
  const { setIsShow = false, isShow = false } = props;
  const { state } = useLocation();
  const navigate = useNavigate();
  const { InvoiceTemplate } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state.userInfo);
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const [showTemplate, setShowTemplate] = useState({
    isOpen: false,
    templateId: 1,
    templateTitle: "",
  });
  const [showSpinner, setShowSpinner] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [showTemplate?.isOpen]);

  const dispatch = useDispatch();

  //check for the modal

  const getDefaultTemplates = async (id) => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}templates/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
            Accept: "application/json",
          },
        }
      );

      if (result?.data?.template) {
        dispatch({
          type: SET_DEFAULT_INVOICE_TEMPLATE,
          data:
            parseInt(result.data?.templateId?.id, 10) || InvoiceTemplate === 1
              ? 3
              : InvoiceTemplate,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_DEFAULT_INVOICE_TEMPLATE,
        // data: parseInt(InvoiceTemplate || 1, 10),
        data:
          parseInt(userInfo?.user_data?.default_voucher_template, 10) ||
          InvoiceTemplate === 1
            ? 3
            : InvoiceTemplate,
      });

      // setShowPopup({
      //   ...showPopup,
      //   is: true,
      //   msg:
      //     error?.data?.error || error?.response?.data?.error || error?.message,
      // });
      console.error("Error fetching default templates:", error.message);
      // return error;
    }
  };
  useEffect(() => {
    getDefaultTemplates(userInfo?.user_data?.id);
    // dispatch({
    //   type: SET_DEFAULT_INVOICE_TEMPLATE,
    //   data: parseInt(userInfo?.user_data?.default_voucher_template, 10)|| InvoiceTemplate,
    // });
  }, []);

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };

  const handleUpdateTemplates = (title, id) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}create-or-update-template/${id}`,
        {
          temp_invoice_id: id?.toString(),
          temp_vouch_id: userInfo?.temp_vouch_id || "0",
          user_id: userInfo?.user_data?.id?.toString(),
          template_type: "voucher",
          name: title,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          dispatch({
            type: SET_DEFAULT_INVOICE_TEMPLATE,
            data: parseInt(res?.data?.templateId?.id, 10),
          });
          toast.success("Default template set for invoice ", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });
        return error;
      });
  };
  const handleCloseTemplate = () => {
    setIsChecked(false);
    setShowSpinner(true);
    setShowTemplate({
      ...showTemplate,
      isOpen: false,
      templateId: 1,
      templateTitle: "",
    });
  };

  return (
    <div className={showTemplate.isOpen ? "blur-background" : ""}>
      {!isShow && <Head title="Invoice Templates" />}
      <Row className="g-3">
        {templates
          ?.sort((a, b) => a.id - b.id)
          .map((item) => (
            <Col md={4}>
              <Card
                className="border-0 shadow-sm"
                onClick={() => {
                  setShowTemplate({
                    ...showTemplate,
                    isOpen: true,
                    templateId: item.id,
                    templateTitle: item.title,
                  });
                }}
              >
                <Card.Body className="p-2">
                  <div
                    className="p-2"
                    style={{
                      background: `url(${item.image})`,
                      height: 220,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  {InvoiceTemplate === item.id && (
                    <div className="p-2 d-flex align-items-center position-absolute bottom-0 bg-light end-0 start-0 rounded justify-content-between">
                      <p className="fs-6 m-0">Default Template</p>
                      <img
                        src="/images/check-mark.png"
                        style={{
                          width: "30px",
                        }}
                        alt="check-icon"
                      />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
      {/* Your main content goes here */}
      <Modal
        className={!isChecked ? "d-block" : "d-none"}
        size="lg"
        center
        show={showTemplate?.isOpen}
        onHide={handleCloseTemplate}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            {!showSpinner && (
              <>
                <Form.Group md="auto">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="button-tooltip">
                        {state?.requestType !== undefined
                          ? "Check to select your template"
                          : "Check to select the template as default"}
                      </Tooltip>
                    }
                  >
                    <Form.Check
                      checked={
                        InvoiceTemplate === showTemplate?.templateId &&
                        !(
                          state?.requestType === "edit" ||
                          isShow?.requestType === "edit"
                        )
                          ? true
                          : isChecked
                      }
                      // onChange={() => setIsChecked(!isChecked)}
                      onChange={() => {
                        if (
                          InvoiceTemplate === showTemplate?.templateId &&
                          !(
                            state?.requestType === "edit" ||
                            isShow?.requestType === "edit"
                          )
                        ) {
                          // toast.warn("This template is already selected as default.");
                          alert(
                            "This template is already selected as default."
                          );
                        } else {
                          setIsChecked(!isChecked);
                        }
                      }}
                      type="checkbox"
                      label={
                        state?.requestType !== undefined
                          ? "Select Your Template"
                          : isShow.id
                          ? "Select Your Template"
                          : "Select Your Default Template"
                      }
                    />
                  </OverlayTrigger>
                </Form.Group>
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "100%" }}>
            {showSpinner ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "90vh" }}
              >
                <Spinner animation="border" role="status" />
              </div>
            ) : (
              <img
                src={
                  templates.filter(
                    (row) => row.id === parseInt(showTemplate?.templateId, 10)
                  )[0]?.showImage
                }
                alt="img"
                width="100%"
                height="100%"
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={
          InvoiceTemplate === showTemplate?.templateId &&
          !(state?.requestType === "edit" || isShow?.requestType === "edit")
            ? false
            : isChecked
        }
        onHide={() => setIsChecked(false)}
        centered
      >
        <Modal.Header className="d-flex justify-content-center border-0">
          <Modal.Title style={{ color: "black" }}>Confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {state?.requestType !== undefined
            ? "Are you sure want to apply this template?"
            : isShow.id
            ? "Are you sure want to apply this template?"
            : "Are you sure want to apply this template as default?"}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="button-tooltip">Cancel the template</Tooltip>}
          >
            <Button
              size="sm"
              variant="secondary"
              className="closeButton"
              onClick={() => setIsChecked(false)}
            >
              Cancel
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="button-tooltip">Apply the template</Tooltip>}
          >
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                if (state?.values && state?.requestType === "edit") {
                  navigate(`/invoices/edit/${state?.values?.id}`, {
                    state: {
                      values: {
                        ...state.values,
                        template_id: parseInt(showTemplate?.templateId, 10),
                      },
                      isTemplateChange: true,
                    },
                  });
                } else if (
                  isShow?.requestType === "edit" ||
                  isShow?.requestType === "view"
                ) {
                  setIsShow({
                    ...isShow,
                    needTemplateChange: false,
                    values: {
                      ...isShow?.values,
                      template_id: parseInt(showTemplate?.templateId, 10),
                    },
                  });
                } else if (state?.values) {
                  navigate("/create-new-invoice", {
                    state: {
                      values: {
                        ...state.values,
                        template_id: parseInt(showTemplate?.templateId, 10),
                      },
                      isTemplateChange: true,
                    },
                  });
                } else {
                  if (isChecked) {
                    handleUpdateTemplates(
                      showTemplate?.templateTitle,
                      showTemplate?.templateId
                    );
                  }
                }
                setIsChecked(false);
                setShowTemplate({
                  ...showTemplate,
                  isOpen: false,
                  templateId: 1,
                  templateTitle: "",
                });
              }}
            >
              Apply
            </Button>
          </OverlayTrigger>
        </Modal.Footer>
      </Modal>
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
      <ToastContainer style={{ top: "60px" }} />{" "}
    </div>
  );
}

export default AllTemplate;
