import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Col from "react-bootstrap/Col";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import {
  startOfYear,
  endOfYear,
  subYears,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import {
  DateRangePicker,
  createStaticRanges,
  defaultStaticRanges,
} from "react-date-range";

function Dashboard({ users }) {
  const { userInfo } = useSelector((state) => state?.userInfo);
  const cashbookState = useSelector((state) => state?.cashbookEntries);
  const { otherInfo } = cashbookState || {};
  const voucherState = useSelector((state) => state?.vouchers?.otherInfo);
  const invoicesState = useSelector((state) => state?.invoices);
  const [invoiceData, setInvoiceData] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection",
    },
  ]);
  const [additionalFilter, setAdditionalFilter] = useState({
    lastTenTransaction: true,
  });
  const [loading, setLoading] = useState(true);
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };
  const handleSelect1 = (ranges) => {
    if (
      isNaN(ranges.selection.startDate.getTime()) &&
      isNaN(ranges.selection.endDate.getTime())
    ) {
      setDateRange([ranges.selection]);
      setAdditionalFilter({ lastTenTransaction: true });
    } else {
      setDateRange([ranges.selection]);
      setAdditionalFilter({
        dateRange: `${moment(ranges.selection.startDate).format(
          "YYYY-MM-DD"
        )} | ${moment(ranges.selection.endDate).format("YYYY-MM-DD")}`,
      });
    }
  };
  const fetchInvoiceData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_VOUCHER_FOR_CHART}`,
        {
          params: {
            dateRange: `${moment(dateRange[0]?.startDate).format(
              "YYYY-MM-DD"
            )} | ${moment(dateRange[0]?.endDate).format("YYYY-MM-DD")}`,
          },
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
            Accept: "application/json",
          },
        }
      );
      const fetchedData = response.data;
      setInvoiceData(fetchedData);
      const totalAmount = response.data.data.reduce(
        (acc, item) => acc + (item.total_amount || 0),
        0
      );
      setTotalExpenses(totalAmount);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching API data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchInvoiceData();
  }, [dateRange]);

  const staticRanges = [
    ...defaultStaticRanges,
    ...createStaticRanges([
      {
        key: "thisYear",
        label: "This Financial Year",
        range: () => {
          const now = new Date();
          const startYear =
            now.getMonth() >= 3 ? now.getFullYear() : now.getFullYear() - 1;
          return {
            startDate: new Date(startYear, 3, 1),
            endDate: new Date(startYear + 1, 2, 31),
          };
        },
      },
      {
        key: "pastYear",
        label: "Past Financial Year",
        range: () => {
          const now = new Date();
          const startYear =
            now.getMonth() >= 3 ? now.getFullYear() - 1 : now.getFullYear() - 2;
          return {
            startDate: new Date(startYear, 3, 1),
            endDate: new Date(startYear + 1, 2, 31),
          };
        },
      },
    ]),
  ].splice(2, 12);

  function aggregateAmountByDate(data = invoiceData.data || []) {
    const aggregatedData = {};

    // data.forEach((item) => {
    //   const currentDate = moment(item.date).format("DD/MM/YYYY");
    //   aggregatedData[currentDate] =
    //     (aggregatedData[currentDate] || 0) + (item.total_amount || 0);
    // });
    const validData = data.filter((item) => item.date && item.total_amount);

    validData.forEach((item) => {
      const currentDate = moment(item.date).format("YYYY-MM-DD");
      aggregatedData[currentDate] =
        (aggregatedData[currentDate] || 0) + (item.total_amount || 0);
    });

    const result = Object.keys(aggregatedData)
      .sort()
      .map((date) => ({
        date: moment(date).format("DD-MM-YYYY"),
        amount: aggregatedData[date],
      }));

    return result;
  }
  const filteredInvoicesArray = aggregateAmountByDate();
  const options = {
    chart: {
      height: 270,
      type: "bar",
    },
    plotOptions: {
      bar: {
        columnWidth: "25px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `₹${value?.toFixed(2)}`;
        },
      },
    },
    series: Array.isArray(filteredInvoicesArray)
      ? [
          {
            name: "Amount",
            data: filteredInvoicesArray.map((item) => item.amount || 0),
          },
        ]
      : [],
    xaxis: {
      categories: Array.isArray(filteredInvoicesArray)
        ? filteredInvoicesArray.map((item) => item.date || 0)
        : [],
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.25,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  };

  const options1 = {
    chart: {
      height: 270,
      type: "bar",
    },
    plotOptions: {
      bar: {
        columnWidth: "47px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `₹${value.toFixed(2)}`;
        },
      },
    },
    series: Array.isArray(filteredInvoicesArray)
      ? [
          {
            name: "Amount",
            data: filteredInvoicesArray.map((item) => item.amount || 0),
          },
        ]
      : [],
    xaxis: {
      categories: Array.isArray(filteredInvoicesArray)
        ? filteredInvoicesArray.map((item) => item.date || 0)
        : [],
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.25,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  };

  const pieChartOptions = {
    chart: {
      type: "pie",
      height: "270px",
    },
    labels: ["Bank", "Cash", "Card", "Cheque", "UPI"],
    series: invoiceData.data
      ? [
          invoiceData.data
            .filter((item) => item.payment_mode === "Bank")
            .reduce((acc, item) => acc + item.total_amount, 0),
          invoiceData.data
            .filter((item) => item.payment_mode === "Cash")
            .reduce((acc, item) => acc + item.total_amount, 0),
          invoiceData.data
            .filter((item) => item.payment_mode === "Card")
            .reduce((acc, item) => acc + item.total_amount, 0),
          invoiceData.data
            .filter((item) => item.payment_mode === "Cheque")
            .reduce((acc, item) => acc + item.total_amount, 0),
          invoiceData.data
            .filter((item) => item.payment_mode === "UPI")
            .reduce((acc, item) => acc + item.total_amount, 0),
        ]
      : [0, 0, 0, 0, 0],
    dataLabels: {
      position: "center",
    },

    legend: {
      position: "bottom",
    },
    colors: ["#3a8dde", "#1dd0bb", "#fd5631", "#01a9d7", "#f17c3a"],
  };

  return (
    <>
      <Row className="g-2 mt-3 m-0">
        <Col>
          <Link
            to="/invoices"
            style={{ textDecoration: "none", color: "#7F7D8D" }}
          >
            <Card>
              <Card.Body>
                <h2 className="m-0 text-gray">
                  {invoicesState?.otherInfo?.recordsTotal ||
                    userInfo?.total_voucher_entries ||
                    0}
                </h2>
                <p className="text-gray m-0" style={{ fontSize: 15 }}>
                  Total Invoices
                </p>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col>
          <Link
            to="/vouchers"
            style={{ textDecoration: "none", color: "#7F7D8D" }}
          >
            <Card>
              <Card.Body>
                <h2 className="m-0 text-gray">
                  {voucherState?.recordsTotal ||
                    userInfo?.total_invoice_entries ||
                    0}
                </h2>
                <p className="text-gray m-0" style={{ fontSize: 15 }}>
                  Total Voucher
                </p>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col>
          <Link
            to="/cashbook"
            style={{ textDecoration: "none", color: "#7F7D8D" }}
          >
            <Card>
              <Card.Body>
                <h2 className="m-0 text-gray">
                  {otherInfo?.total_cashbook_entries !== undefined ? (
                    <>{otherInfo?.total_cashbook_entries || 0}</>
                  ) : (
                    <>{userInfo?.total_cash_entries || 0}</>
                  )}
                  {/* {userInfo?.total_cash_entries || 0} */}
                  {/* { otherInfo?.total_cashbook_entries || 0} */}
                </h2>
                <p className="text-gray m-0" style={{ fontSize: 15 }}>
                  Total Cash Entries
                </p>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col>
          <Link
            to="/cashbook"
            style={{ textDecoration: "none", color: "#7F7D8D" }}
          >
            <Card>
              <Card.Body>
                <h2 className="m-0 text-gray">
                  ₹
                  {otherInfo?.balance !== undefined ? (
                    <>{parseFloat(otherInfo?.balance)?.toFixed(2) || 0}</>
                  ) : (
                    <>
                      {(
                        parseFloat(userInfo?.cashbook_in_amount) -
                        parseFloat(userInfo?.cashbook_out_amount)
                      )?.toFixed(2) || 0}
                    </>
                  )}
                </h2>
                <p className="text-gray m-0" style={{ fontSize: 15 }}>
                  Cashbook Net Balance
                </p>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>

      <Card className="mt-4 m-1">
        <Card.Header className="d-flex justify-content-between align-items-center py-3">
          <h6 className="m-0">Projected Voucher</h6>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip">Click to select date range</Tooltip>
            }
          >
            <Dropdown autoClose="outside">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                size="sm"
              >
                {`${moment(dateRange[0].startDate).format(
                  "DD MMM, YYYY"
                )} - ${moment(dateRange[0].endDate).format("DD MMM, YYYY")}`}
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 overflow-hidden">
                <Dropdown.Item className="p-0 ">
                  <DateRangePicker
                    ranges={dateRange}
                    onChange={handleSelect}
                    editableDateInputs={true}
                    staticRanges={staticRanges}
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </OverlayTrigger>
        </Card.Header>
        {loading ? (
          <Card.Body
            className="p-2 fw-semibold fs-6"
            style={{
              height: "360px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Spinner
                animation="border"
                variant="danger"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              />
              <span>Loading...</span>
            </div>
          </Card.Body>
        ) : (
          <Card.Body className="p-2">
            {filteredInvoicesArray && filteredInvoicesArray.length > 0 ? (
              ""
            ) : (
              <Alert variant="warning">
                No data available for the selected date range.
              </Alert>
            )}
            <Row className="mt-4">
              <Col className="overflow-hidden">
                <ApexCharts
                  options={options}
                  series={options.series}
                  type="bar"
                  height={295}
                />
              </Col>
              <Col xs="auto">
                <ApexCharts
                  options={pieChartOptions}
                  series={pieChartOptions.series}
                  type="pie"
                  height={300}
                />
                <p className=" text-center mb-0">
                  Total Expenses: ₹{totalExpenses.toFixed(2)}
                </p>
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </>
  );
}
export default Dashboard;
