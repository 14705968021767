import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { onLogIn } from "../store/Auth/AuthActions";
import { SET_DEFAULT_VOUCHER_TEMPLATE } from "../store/Theme/ThemeActionTypes";
import { SET_DEFAULT_INVOICE_TEMPLATE } from "../store/Theme/ThemeActionTypes";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is a required field")
    .email("Please enter valid email address")
    .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
  password: yup
    .string()
    .required("Password is a required field")
    .max(12, "You can enter only 12 characters")
    .min(5, "Enter Atleast 5 characters"),
});

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleSubmit } = useForm();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const submit = (value) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_LOGIN_URL}`,
        {
          email: value.email,
          password: value.password,
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          dispatch(onLogIn({ ...response.data, showBanner: true }));
          if (response.data?.user_data.default_invoice_template) {
            dispatch({
              type: SET_DEFAULT_INVOICE_TEMPLATE,
              data: parseInt(
                response.data?.user_data.default_invoice_template,
                10
              ),
            });
          }
          if (response.data?.user_data.default_voucher_template) {
            dispatch({
              type: SET_DEFAULT_VOUCHER_TEMPLATE,
              data: parseInt(
                response.data?.user_data.default_voucher_template,
                10
              ),
            });
          }

          if (
            response?.data?.account_preferences &&
            Object.keys(response?.data?.account_preferences)?.length > 0
          ) {
            navigate("/");
          } else {
            navigate("/account_preferences");
          }
        }
      })
      .catch((error) => {
        toast.error("Login Credentials does not match", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsDisabled(true);
        setTimeout(() => {
          setIsDisabled(false);
        }, 3000);
      });
  };

  return (
    <>
      <div className="pattern-square"></div>
      <section className="py-5 py-lg-8">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 offset-xl-4 col-md-12 col-12">
              <div className="text-center">
                <h1 className="mb-1">eBilling</h1>
                <p className="mb-0">
                  Empowering Your Finances, Mastering Your Bills
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8 col-12">
              <div className="card shadow-sm mb-6">
                <div className="card-body">
                  <Formik
                    validationSchema={schema}
                    noValidate
                    onSubmit={(formvalue) => {
                      handleSubmit(submit(formvalue));
                    }}
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleChange2,
                      handleBlur,
                      values,
                      touched,
                      isValid,

                      errors,
                      isSubmitting,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Email
                            <span
                              style={{
                                color: "red",
                                fontSize: "15px",
                              }}
                            >
                              &#42;
                            </span>
                          </Form.Label>
                          <Form.Control
                            name="email"
                            type="email"
                            value={values.email}
                            placeholder="abc12@iphtechnologies.com"
                            className="txtfield"
                            onChange={handleChange}
                            isInvalid={!!errors.email && !!touched.email}
                          />
                          {touched?.email && errors.email && (
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>
                            Password
                            <span
                              style={{
                                color: "red",
                                fontSize: "15px",
                              }}
                            >
                              &#42;
                            </span>
                          </Form.Label>

                          <InputGroup
                            className="rtrtrr"
                            style={{ border: "none", outline: "none" }}
                          >
                            <Form.Control
                              name="password"
                              type={passwordShown ? "text" : "password"}
                              placeholder="Password"
                              className="txtfield"
                              value={values.password}
                              onChange={handleChange}
                              maxLength={13}
                              isInvalid={
                                !!errors.password && !!touched.password
                              }
                            />
                            <InputGroup.Text
                              className="login-hide"
                              onClick={() =>
                                passwordShown
                                  ? setPasswordShown(false)
                                  : setPasswordShown(true)
                              }
                            >
                              {passwordShown ? (
                                <AiOutlineEye />
                              ) : (
                                <AiOutlineEyeInvisible />
                              )}
                            </InputGroup.Text>
                          </InputGroup>

                          {touched?.password && errors.password && (
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {errors.password}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="my-3"
                          controlId="formBasicCheckbox"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Form.Check type="checkbox" label="Remember me" />
                            <Form.Text></Form.Text>
                          </div>
                        </Form.Group>
                        <Button
                          className="w-100"
                          type="submit"
                          disabled={isDisabled}
                        >
                          {isDisabled ? (
                            <Spinner animation="border" size="sm" role="status">
                              <span className="sr-only"></span>
                            </Spinner>
                          ) : (
                            "Sign in"
                          )}
                        </Button>
                        <ToastContainer style={{ top: "60px" }} />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
